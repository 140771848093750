import React from 'react'

import styles from "./FormContainer.module.css"

import { Link } from 'react-router-dom'

function FormContainer({ children, linkUrl }) {
  const message = linkUrl === "/" ? "Sign up" : "Login"
  const toPage = linkUrl === "/" ? "Login" : "Sign up"

  return (
    <div className={styles.formContainer}>
        <img  src="/icons/logo-ITFest.svg" alt='logo ITFest' height={50} />
        <div className={styles.formHeader}>
            <h1 className={styles.headerMain}>{message}</h1>
            <p>
              or <Link to={linkUrl} style={{ textDecoration: "underline" }}>{toPage}</Link>
            </p>
        </div>
        {children}
    </div>
  )
}

export default FormContainer