import React from 'react'
import styles from './AchievementPage.module.css'
import AchievementCard from '../../../components/achievementCard/AchievementCard'
import Description from "../../../components/description/Description"
import { achievementsDescription } from "../../../staticData/general/achievementsDescription"

import { achievementJobHub } from "../../../staticData/achievements/jobhubAchievement"
import { achievementWorkshop } from "../../../staticData/achievements/workshopAchievement"

const achievements = [achievementWorkshop, achievementJobHub]

function AchievementPage() {
  return (
    <div>
      <Description text={achievementsDescription} />

      <div className={styles.cardsContainer}>
        {achievements.map((achievementData) => {
          return (
          <AchievementCard key={achievementData.id} eventData={achievementData} />
      )
        })}
      </div>
    </div>
  )
}

export default AchievementPage