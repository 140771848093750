export const workshopRules = (
  <div class="c3 c20 doc-content">
    <p class="c10">
      <span class="c5 c3 c18 c24"></span>
    </p>
    <ol class="c13 lst-kix_f044gg51jqhd-0 start" start="1">
      <li class="c7 c11 li-bullet-0">
        <h1 id="h.gjdgxs">
          <span class="c5 c14 c3 c17">Organizator</span>
        </h1>
      </li>
    </ol>
    <p class="c6 c25">
      <span class="c5 c8"></span>
    </p>
    <p class="c9 c12">
      <span class="c1 c3">
        Sindicatul Studenților din Facultatea de Cibernetică, Statistică și
        Informatică Economică (SiSC)
      </span>
      <span class="c5 c0">
        &nbsp;este cea mai veche organizație studențească din Academia de Studii
        Economice, fiind, totodată, și membru activ ANOSR.
      </span>
    </p>
    <p class="c9">
      <span class="c0">
        În cei 27 de ani de implicare, profesionalism și inovație, &nbsp;
      </span>
      <span class="c1 c3">peste 200 de proiecte</span>
      <span class="c5 c0">&nbsp;au fost desfășurate cu succes.</span>
    </p>
    <p class="c9 c12">
      <span class="c1 c3">Misiunea SiSC</span>
      <span class="c0">&nbsp;cuprinde </span>
      <span class="c1 c3">reprezentarea studenților</span>
      <span class="c0">
        &nbsp;din Facultatea de Cibernetică, Statistică și Informatică
        Economică,{" "}
      </span>
      <span class="c1 c3">susținerea drepturilor studenților</span>
      <span class="c0">&nbsp;în mediul academic și </span>
      <span class="c1 c3">dezvoltarea acestora</span>
      <span class="c0">&nbsp;</span>
      <span class="c1 c3">atât pe plan personal, cât și profesional</span>
      <span class="c5 c0">, în cadrul proiectelor SiSC.</span>
    </p>
    <p class="c9 c16">
      <span class="c5 c1 c3"></span>
    </p>
    <p class="c9 c12">
      <span class="c0">Organizatorul proiectului ITFest și, implicit, al </span>
      <span class="c0">W</span>
      <span class="c0">orkshopului </span>
      <span class="c0">este </span>
      <span class="c1 c3">
        Sindicatul Studenților din Facultatea de Cibernetică, Statistică și
        Informatică Economică (SiSC),
      </span>
      <span class="c0 c5">
        &nbsp;denumit în continuare „Organizator”, în parteneriat cu Facultatea
        de Cibernetică, Statistică și Informatică Economică.
      </span>
    </p>
    <p class="c9">
      <span class="c5 c0">
        Evenimentul se va derula cu respectarea prevederilor cuprinse în
        prezentul regulament (denumit în cele ce urmează „Regulamentul”).
        Termenii și condițiile prezentului Regulament, așa cum sunt prezentate
        mai jos, sunt obligatorii pentru toți participanții evenimentului.
      </span>
    </p>
    <p class="c9 c12">
      <span class="c5 c0">
        Organizatorul își rezervă dreptul de a modifica Regulamentul, aducând la
        cunoștința publicului acest fapt în timp util.
      </span>
    </p>
    <p class="c9 c16 c12">
      <span class="c5 c0"></span>
    </p>
    <ol class="c13 lst-kix_f044gg51jqhd-0" start="2">
      <li class="c7 c11 li-bullet-0">
        <h1 id="h.g051z0whfne1">
          <span class="c5 c14 c3 c17">Descrierea proiectului ITFest</span>
        </h1>
      </li>
    </ol>
    <p class="c6">
      <span class="c5 c8"></span>
    </p>
    <p class="c9 c12">
      <span class="c0">ITFest este un proiect cu tradiție, aflat la cea </span>
      <span class="c0">de</span>
      <span class="c2">-a </span>
      <span class="c1">XVI-a ediție</span>
      <span class="c1 c3">.</span>
      <span class="c0">
        &nbsp;Prin intermediul diverselor evenimente, ITFest își propune să
        contribuie la diminuarea unor slăbiciuni ale sistemului educațional,
        precum: lipsa contextelor în care tinerii se pot afirma în fața
        angajatorilor, imposibilitatea tinerilor de a dobândi experiența
        necesară angajării,{" "}
      </span>
      <span class="c0">neadaptarea</span>
      <span class="c5 c0">
        &nbsp;sistemului educațional la nevoile pieței muncii sau existența unui
        caracter preponderent teoretic al sistemului educațional.
      </span>
    </p>
    <p class="c9 c12">
      <span class="c0">
        Evenimentul se dezvoltă în jurul sferei IT și cuprinde{" "}
      </span>
      <span class="c1 c3">Job Hub</span>
      <span class="c0">, </span>
      <span class="c1 c3">Career Launch, </span>
      <span class="c1 c3">Workshop-</span>
      <span class="c1 c3">ul</span>
      <span class="c0">&nbsp;</span>
      <span class="c0">tematic</span>
      <span class="c0">&nbsp;și </span>
      <span class="c1 c3">Hackathon</span>
      <span class="c0">. </span>
      <span class="c0">Ediția din acest an se va desfășura în perioada</span>
      <span class="c2">&nbsp;</span>
      <span class="c1">6</span>
      <span class="c1">&nbsp;și </span>
      <span class="c1">28</span>
      <span class="c1">&nbsp;octombrie</span>
      <span class="c5 c1">.</span>
    </p>
    <p class="c9 c16">
      <span class="c5 c1 c19"></span>
    </p>
    <ol class="c13 lst-kix_f044gg51jqhd-0" start="3">
      <li class="c7 c11 li-bullet-0">
        <h1 id="h.30j0zll">
          <span class="c5 c14 c3 c17">Aspecte organizatorice</span>
        </h1>
      </li>
    </ol>
    <p class="c6">
      <span class="c5 c8 c22"></span>
    </p>
    <p class="c9 c12">
      <span class="c2">Workshop-u</span>
      <span class="c2">l va avea loc în perioada </span>
      <span class="c1">25/26</span>
      <span class="c1">&nbsp;octombrie 2023</span>
      <span class="c5 c2">.</span>
    </p>
    <p class="c9 c12">
      <span class="c0">Acestea vor fi organizate</span>
      <span class="c1 c3">&nbsp;în format fizic</span>
      <span class="c0">
        &nbsp;și se vor desfășura în conformitate cu prevederile prezentului
        Regulament, fiind accesibile oricărei persoane fizice înscrise într-un
        program de studii preuniversitare (liceu) sau universitare,
      </span>
      <span class="c0 c21">&nbsp;</span>
      <span class="c5 c0">
        care acceptă termenii și condițiile prezentului Regulament.
      </span>
    </p>
    <p class="c9 c16">
      <span class="c5 c0"></span>
    </p>
    <ol class="c13 lst-kix_f044gg51jqhd-0" start="4">
      <li class="c7 c11 li-bullet-0">
        <h1 id="h.1fob9te">
          <span class="c5 c14 c3 c17">Dreptul de participare</span>
        </h1>
      </li>
    </ol>
    <p class="c6">
      <span class="c5 c8"></span>
    </p>
    <p class="c9 c12">
      <span class="c5 c0">
        Poate participa orice persoană fizică înscrisă într-un program de studii
        preuniversitare (liceu) sau universitare.
      </span>
    </p>
    <p class="c9 c12">
      <span class="c0">
        Pentru a putea participa la Workshop-uri, persoanele trebuie să se
        înscrie completând{" "}
      </span>
      <span class="c1 c3">formularul de înregistrare</span>
      <span class="c0">&nbsp;aflat pe site-ul </span>
      <span class="c1 c3 c15">
        <a
          class="c4"
          href="https://www.google.com/url?q=https://itfest.sisc.ro/&amp;sa=D&amp;source=editors&amp;ust=1695064508509701&amp;usg=AOvVaw03HrviVAiXYO444nnl5DMH"
        >
          https://itfest.sisc.ro/
        </a>
      </span>
      <span class="c0">&nbsp;</span>
      <span class="c5 c0">
        &nbsp;și să își încarce CV-ul în cadrul formularului.
      </span>
    </p>
    <p class="c9 c16">
      <span class="c5 c0"></span>
    </p>
    <ol class="c13 lst-kix_f044gg51jqhd-0" start="5">
      <li class="c7 c11 li-bullet-0">
        <h1 id="h.2et92p0">
          <span class="c5 c14 c3 c17">
            &nbsp;Înscrierea și selectarea participanților
          </span>
        </h1>
      </li>
    </ol>
    <p class="c6">
      <span class="c5 c8"></span>
    </p>
    <p class="c9 c12">
      <span class="c1">Înscrierea</span>
      <span class="c2">&nbsp;persoanelor se va face în perioada </span>
      <span class="c1">16-23 octombrie</span>
      <span class="c1">&nbsp;2023</span>
      <span class="c2">.</span>
      <span class="c2 c22">&nbsp;</span>
      <span class="c0">Doritorii trebuie să se înscrie completând </span>
      <span class="c1 c3">formularul de înregistrare</span>
      <span class="c0">&nbsp;aflat pe site-ul </span>
      <span class="c1 c15 c3">
        <a
          class="c4"
          href="https://www.google.com/url?q=https://itfest.sisc.ro/&amp;sa=D&amp;source=editors&amp;ust=1695064508510979&amp;usg=AOvVaw1kAH1lkvhXkisf_OmIGKFn"
        >
          https://itfest.sisc.ro/
        </a>
      </span>
      <span class="c0">&nbsp;</span>
      <span class="c0">și </span>
      <span class="c1 c3">să își încarce CV-ul</span>
      <span class="c5 c0">
        &nbsp;în cadrul formularului. După această dată nu se vor mai accepta
        alte aplicații. Înscrișii au obligația să furnizeze informații reale și
        complete în formularul de înscriere la eveniment.
      </span>
    </p>
    <p class="c9 c12">
      <span class="c5 c0">
        Cei care s-au înscris declară expres că au citit, au înțeles și sunt de
        acord cu termenii și condițiile prezentului Regulament. Nu se percep
        taxe de înscriere.
      </span>
    </p>
    <p class="c9 c16 c12">
      <span class="c5 c0"></span>
    </p>
    <p class="c9 c16">
      <span class="c5 c0"></span>
    </p>
    <ol class="c13 lst-kix_f044gg51jqhd-0" start="6">
      <li class="c7 c11 li-bullet-0">
        <h1 id="h.w8uvkfcfx367">
          <span class="c5 c14 c17">Obligațiile Participanților</span>
        </h1>
      </li>
    </ol>
    <p class="c6">
      <span class="c5 c8"></span>
    </p>
    <p class="c9 c12">
      <span class="c0">
        Prezentele obligații trebuie respectate pe durata perioadei{" "}
      </span>
      <span class="c0">Workshopu</span>
      <span class="c5 c0">
        lui, precum și după finalizarea sa. Participanții își asumă:
      </span>
    </p>
    <ul class="c13 lst-kix_3rv0xlxjn88e-0 start">
      <li class="c7 li-bullet-0">
        <span class="c5 c0">
          să nu furnizeze public informații care pot aduce daune imaginii
          Organizatorului;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c5 c0">
          să păstreze un comportament decent și să respecte indicațiile
          Organizatorului;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c5 c0">
          să își asigure singuri modalitățile de acces la evenimente (laptop,
          conexiune la internet etc.);
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c0">să nu înregistreze și să difuzeze conținutul </span>
        <span class="c0">Workshopu</span>
        <span class="c5 c0">lui;</span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c0">ca</span>
        <span class="c0">
          &nbsp;materialele obținute de către Organizator în urma evenimentelor
          (fotografiile și înregistrările video și audio){" "}
        </span>
        <span class="c0">să fie făcute publice pe site-ul </span>
        <span class="c1 c3">
          <a
            class="c4"
            href="https://www.google.com/url?q=http://itfest.sisc.ro&amp;sa=D&amp;source=editors&amp;ust=1695064508513836&amp;usg=AOvVaw1TmPfQpCE1m0FqwujHJhiI"
          >
            http://itfest.sisc.ro
          </a>
        </span>
        <span class="c0">, pe Instagram la </span>
        <span class="c1 c3">
          <a
            class="c4"
            href="https://www.google.com/url?q=https://www.instagram.com/sisc.ase/?hl%3Dro&amp;sa=D&amp;source=editors&amp;ust=1695064508514318&amp;usg=AOvVaw3-5cjrZKc3DZa5VJjKgsGj"
          >
            @sisc.ase
          </a>
        </span>
        <span class="c0">, pe paginile de Facebook </span>
        <span class="c1 c3">
          <a
            class="c4"
            href="https://www.google.com/url?q=https://www.facebook.com/it.fest.sisc&amp;sa=D&amp;source=editors&amp;ust=1695064508514662&amp;usg=AOvVaw281p50OImoS0MbyTos99g5"
          >
            ITFest
          </a>
        </span>
        <span class="c0">&nbsp;și </span>
        <span class="c1 c3">
          <a
            class="c4"
            href="https://www.google.com/url?q=https://www.facebook.com/sisc.ase&amp;sa=D&amp;source=editors&amp;ust=1695064508515026&amp;usg=AOvVaw3fsx0c1CEkT9HtOPoitWeA"
          >
            SiSC ASE
          </a>
        </span>
        <span class="c0">.</span>
      </li>
    </ul>
    <p class="c9 c12">
      <span class="c5 c0">
        Participanții precum și orice persoană care va fi prezentă pe perioada
        desfășurării evenimentelor înțeleg, sub sancțiunea suportării tuturor
        consecințelor generate de încălcarea acestor obligații.
      </span>
    </p>
    <p class="c9 c16">
      <span class="c5 c0"></span>
    </p>
    <ol class="c13 lst-kix_f044gg51jqhd-0" start="7">
      <li class="c7 c11 li-bullet-0">
        <h1 id="h.udy6dsyho3oe">
          <span class="c5 c14 c17">Confidențialitatea datelor</span>
        </h1>
      </li>
    </ol>
    <p class="c6">
      <span class="c5 c8"></span>
    </p>
    <p class="c9 c12">
      <span class="c5 c0">
        Organizatorul are următoarele obligații în ceea ce privește
        confidențialitatea datelor:{" "}
      </span>
    </p>
    <ul class="c13 lst-kix_6x7e3ls6r2y4-0 start">
      <li class="c7 li-bullet-0">
        <span class="c0">respectarea și protejarea </span>
        <span class="c1 c3">confidențialității datelor personale</span>
        <span class="c0">&nbsp;</span>
        <span class="c0">din</span>
        <span class="c5 c0">&nbsp;formularele de înscriere;</span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c5 c0">
          prelucrarea și folosirea informațiilor personale conform politicii de
          confidențialitate a datelor personale;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c5 c0">
          informațiile personale nu vor fi folosite în niciun mod care
          contravine politicii menționate anterior;
        </span>
      </li>
      <li class="c7 li-bullet-0">
        <span class="c5 c0">
          materialele trimise de Participanți nu vor fi folosite în alte scopuri
          ce nu țin de eveniment.
        </span>
      </li>
    </ul>
    <p class="c9 c12">
      <span class="c5 c0">
        Organizatorul își rezervă dreptul de a oferi partenerilor CV-urile
        Participanților.
      </span>
    </p>
    <p class="c9 c16">
      <span class="c5 c0"></span>
    </p>
    <ol class="c13 lst-kix_f044gg51jqhd-0" start="8">
      <li class="c7 c11 li-bullet-0">
        <h1 id="h.3dy6vkm">
          <span class="c5 c14 c3 c17">Dreptul de autor</span>
        </h1>
      </li>
    </ol>
    <p class="c6">
      <span class="c5 c8"></span>
    </p>
    <p class="c9 c12">
      <span class="c0">
        Prezentările lucrărilor sau a oricăror alte materiale realizate în
        vederea concursului{" "}
      </span>
      <span class="c1 c3">nu vor rămâne în proprietatea Organizatorului</span>
      <span class="c5 c0">.</span>
    </p>
    <p class="c9 c12">
      <span class="c5 c0">
        Organizatorul nu își asumă nicio răspundere în cazul în care lucrările
        realizate în urma concursului folosesc elemente de conținut (texte,
        imagini, coduri sursă etc.) care încalcă drepturile de autor. Întreaga
        răspundere aparține celui care a realizat lucrarea.
      </span>
    </p>
    <p class="c9 c16">
      <span class="c5 c0"></span>
    </p>
    <ol class="c13 lst-kix_f044gg51jqhd-0" start="9">
      <li class="c7 c11 li-bullet-0">
        <h1 id="h.2s8eyo1">
          <span class="c5 c14 c3 c17">Litigii</span>
        </h1>
      </li>
    </ol>
    <p class="c6">
      <span class="c5 c8"></span>
    </p>
    <p class="c9 c12">
      <span class="c0">
        Eventualele litigii apărute între Organizator și Participant se vor
        rezolva pe cale{" "}
      </span>
      <span class="c1 c3">amiabilă</span>
      <span class="c5 c0">.</span>
    </p>
    <p class="c9 c12">
      <span class="c0">
        În cazul în care un Participant are de semnalat un incident în legătură
        cu desfășurarea evenimentului, se va adresa Organizatorului cu o{" "}
      </span>
      <span class="c1 c3">sesizare scrisă</span>
      <span class="c0">&nbsp;în acest sens trimisă pe adresa </span>
      <span class="c1 c3">itfest@sisc.ro</span>
      <span class="c5 c0">
        , în termen de maximum 15 zile de la data incidentului, Organizatorul
        fiind obligat să ofere un răspuns în maximum 30 de zile.
      </span>
    </p>
    <p class="c9 c12">
      <span class="c2">Organizarea proiectului </span>
      <span class="c1">poate fi întreruptă</span>
      <span class="c5 c2">
        &nbsp;doar în cazul apariţiei unor evenimente importante neprevăzute sau
        din motive independente de voinţa Organizatorilor.
      </span>
    </p>
    <p class="c9 c16">
      <span class="c5 c2"></span>
    </p>
    <ol class="c13 lst-kix_f044gg51jqhd-0" start="10">
      <li class="c7 c11 li-bullet-0">
        <h1 id="h.35nkun2">
          <span class="c5 c14 c17">Dispoziții finale</span>
        </h1>
      </li>
    </ol>
    <p class="c6">
      <span class="c5 c8"></span>
    </p>
    <p class="c9 c12">
      <span class="c2">Prezentul Regulament este valabil pentru </span>
      <span class="c1">ediția din anul 2023</span>
      <span class="c5 c2">
        &nbsp;a proiectului ITFest și are caracter obligatoriu, atât pentru
        Organizator, cât și pentru Participanți.{" "}
      </span>
    </p>
    <p class="c9 c12">
      <span class="c2">
        Alte acţiuni întreprinse de Organizator şi nereglementate de către
        regulament, vor fi analizate de ambele părţi spre un{" "}
      </span>
      <span class="c1">acord comun</span>
      <span class="c5 c2">
        , atât al Participanţilor, cât şi al Organizatorilor, astfel încât
        aceste acţiuni să nu pună în pericol desfăşurarea normală a proiectului.
      </span>
    </p>
    <p class="c9 c12">
      <span class="c2">Prezentul </span>
      <span class="c2">Regulament</span>
      <span class="c2">&nbsp;este disponibil în format online pe </span>
      <span class="c2">site-ul</span>
      <span class="c2">&nbsp;</span>
      <span class="c1 c15">
        <a
          class="c4"
          href="https://www.google.com/url?q=https://itfest.sisc.ro/&amp;sa=D&amp;source=editors&amp;ust=1695064508521458&amp;usg=AOvVaw05fwQDV3rqyLLFj923aNcy"
        >
          https://itfest.sisc.ro/
        </a>
      </span>
      <span class="c5 c2">&nbsp;. </span>
    </p>
    <p class="c6">
      <span class="c5 c8"></span>
    </p>
  </div>
);
