import React from 'react'
import styles from "./Description.module.css"

function Description({text}) {
  return (
    <div className={styles.eventsDescriere}>
      {text}
    </div>
  )
}

export default Description