import React, { useEffect, useState } from 'react';
import styles from'./AttendanceStatus.module.css';
import Axios  from 'axios';
import { URL } from '../../constants';


function AttendanceStatus({eventID}) {
   const [signedUpUsers, setSignedUpUsers] = useState();
   const [participants, setParticipants] = useState();

   useEffect(()=>{
    Axios.get(`${URL}/attendance/signedUps/${eventID}`).then((res)=>{
      console.log(res)
        setSignedUpUsers(res.data)
    }).catch((error)=>{
        console.log(error)
    })

    Axios.get(`${URL}/attendance/participants/${eventID}`).then((res)=>{
      console.log(res)
      setParticipants(res.data)
    }).catch((error)=>{
        console.log(error)
    })

   },[])

  return (
    <div>
        <div className={styles.attendaceContainer}>Participanti:{participants}/Inscrisi:{signedUpUsers}</div>
    </div>
  )
}

export default AttendanceStatus