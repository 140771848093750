import styles from "./GreetMessage.module.css";

import useStore from "../../store/userStore";

function GreetMessage() {
  const user = useStore((state) => state.user);

  return (
    <h2>
      Bine ai venit, <span className={styles.firstName}>{user.firstName}</span>!
      <br />
      Id-ul Utilizator: <span className={styles.firstName}>{user.id}</span>
    </h2>
  );
}
export default GreetMessage;
