import {
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import { Route } from "react-router-dom";
import App from "../App";
import RoleBasedAccess from "./RoleBasedAccess";

import Sidebar from "../components/sidebar/Sidebar";

import LogInPage from "../pages/user-pages/log-in/LogInPage";
import SignUpPage from "../pages/user-pages/sign-up/SignUpPage";
import ProfilePage from "../pages/user-pages/profile/ProfilePage";
import HackathonPage from "../pages/user-pages/hackathon/HackathonPage";
import CareerLaunchPage from "../pages/user-pages/career-launch/CareerLaunchPage";
import AchievementPage from "../pages/user-pages/achievements/AchievementPage";
import GiveawayPage from "../pages/user-pages/giveaway/GiveawayPage";
import JobHubPage from "../pages/user-pages/job-hub/JobHubPage";
import WorkshopPage from "../pages/user-pages/workshop/WorkshopPage";
import DashboardPage from "../pages/admin-pages/dashboard/DashboardPage";
import QRPage from "../pages/admin-pages/qr-code/QRPage";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<App />}>
        {/* rute user neautentificat */}
        <Route path="/" element={<LogInPage />} />
        <Route path="sign-up" element={<SignUpPage />} />

        {/* rute user */}
        <Route
          element={
            <RoleBasedAccess isAdmin={false} WrapperComponent={Sidebar} />
          }
        >
          <Route path="profile" element={<ProfilePage />} />
          <Route path="job-hub" element={<JobHubPage />} />
          <Route path="career-launch" element={<CareerLaunchPage />} />
          <Route path="workshop" element={<WorkshopPage />} />
          <Route path="hackathon" element={<HackathonPage />} />
          <Route path="giveaway" element={<GiveawayPage />} />
          <Route path="achievements" element={<AchievementPage />} />
        </Route>

        {/* rute admin */}
        <Route
          element={
            <RoleBasedAccess isAdmin={true} WrapperComponent="AdminWrapper" />
          }
        >
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="users-data" element="Pagina users data" />
          <Route path="qr-code" element={<QRPage />} />
        </Route>
      </Route>
    </>
  )
);
