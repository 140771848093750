import React from 'react'
import styles from "./CompanyCard.module.css"
import Details from './Details'
import Rules from './Rules'

function CompanyCard({ children, eventData }) {
  const { details, rules, companyLogo } = eventData

  return (
    <div className={styles.cardContainer}>
      <div className={styles.innerCardContainer} style={{
        backgroundImage: `url(${companyLogo})`,
      }}>
        <div className={styles.detailsAndRulesContainer}>
          {eventData?.details && <Details details={details} />}
          {eventData?.rules && <Rules rules={rules} />}
        </div>
      </div>

      {children}
    </div>
  )
}

export default CompanyCard