export const careerLaunchDescription = (
  <div class="c7 doc-content">
    <p class="c3">
      <span class="c6">&nbsp;&nbsp;&nbsp;&nbsp;Career Launch</span>
      <span class="c4"> are ca scop</span>
      <span class="c4">&nbsp;familiarizarea studenți</span>
      <span class="c4">
        lor cu procesul de recrutare. Toată lumea este conștientă că
        interviurile de angajare pot fi tricky din multe puncte de vedere, mai
        ales dacă nu ai o sursă de unde să te pregătești sau nu ai niciun fel de
        material pe care să îl citești.
      </span>
    </p>

    <p>
      <span class="c1">
        &nbsp;&nbsp;&nbsp;&nbsp;Astfel că, prin Career Launch
      </span>
      <span class="c0">
        &nbsp;vei putea trece prin doua etape în urma cărora te vei simți mai
        confortabil cu procesul de recrutare din domeniul IT. În prima etapa,
        vei susține testele tehnice online ale companiilor partenere, prin care
        iti vei putea evalua skill-urile de algoritmică. Apoi, urmează ca noi și
        partenerii să alegem participanți care s-au descurcat cel mai bine
        pentru etapa a doua. Această ultimă &nbsp;etapă a Career Launch va avea
        loc la sediul companiei și va fi constituită dintr-o prezentare a
        companiei.{" "}
      </span>
    </p>

    <p class="c3">
      <span class="c0">
        &nbsp;&nbsp;&nbsp;&nbsp;Pentru a vă înscrie la eveniment, trebuie doar
        să susțineți testele tehnice și să aveți pregătit CV-ul care va
        impresiona angajatorii!{" "}
      </span>
    </p>

    <p>6 - 10 octombrie: Adobe</p>
    <p>6 - 12 octombrie: Systematic</p>
  </div>
);
