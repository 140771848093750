export const jobHubCompanies = [
  // compania unu
  {
    companyLogo: "/icons/adobe-icon.png",
    details: {
      title: "Adobe", // nume companie
      // descriere companie
      description: (
        <div class="c18 doc-content">
          <p class="c14">
            <span class="c0">Viitorul e al tău! Creează-l! </span>
          </p>
          <p class="c12">
            <span class="c5">
              Adobe Romania este cel mai mare centru de cercetare și dezvoltare
              al produselor Adobe din Europa.{" "}
            </span>
          </p>
          <p class="c19">
            <span class="c5">
              Cu peste 1000 de angajați în prezent, compania lucrează la cele
              mai cunoscute produse Adobe din lume, &nbsp;precum Adobe Express,
              Adobe Experience Manager, Adobe Target, Adobe Analytics.{" "}
            </span>
          </p>
          <p class="c20">
            <span class="c5">
              La Adobe, vei contribui la crearea de produse software pregătite
              să schimbe lumea și felul în care &nbsp;lucrăm. De la semnături
              electronice, până la filme de succes, și site-uri de comerț
              electronic, Adobe &nbsp;creează produse ce acoperă o gamă largă de
              nevoi ale clienților noștri.{" "}
            </span>
          </p>
          <p class="c9">
            <span class="c5">
              Când ni te alaturi fie ca student, fie ca absolvent universitar,
              vei lucra cot la cot cu echipele noastre de &nbsp;ingineri, la
              produse live. De asemenea, vei deveni parte dintr-o comunitate
              care prețuiește perspectiva &nbsp;ta și iți așteaptă sfaturile și
              ideile inovative.{" "}
            </span>
          </p>
          <p class="c17">
            <span class="c5">
              Diversitatea și incluziunea sunt unele dintre obiectivele Adobe.
              Compania investește în programe și &nbsp;evenimente interne ce au
              ca scop crearea unui mediu de lucru cât mai confortabil și deschis
              pentru toți &nbsp;angajații. Adobe Romania oferă oportunități
              egale tuturor angajaților și îi susține în dezvoltarea
              &nbsp;profesională. Pentru noi asta înseamnă #adobelife, un loc în
              care toți colegii pot fi ei înșiși și se pot &nbsp;exprima liber.{" "}
            </span>
          </p>
          <p class="c22">
            <span class="c5">
              Dacă vrei să afli mai multe despre programele noastre de
              internship și oportunități în carieră, folosește &nbsp;link-ul de
              mai jos:{" "}
            </span>
          </p>
          <p class="c23">
            <a
              href="https://www.adobe.com/careers/university.html"
              target="_blank"
              class="c6"
            >
              https://www.adobe.com/careers/university.html
            </a>
            <span class="c10">&nbsp;</span>
          </p>
          <p class="c2">
            <span class="c0">The future is yours! Create it! </span>
          </p>
          <p class="c7">
            <span class="c3">
              Adobe Romania is the largest product development center in Europe.{" "}
            </span>
          </p>
          <p class="c1">
            <span class="c3">
              With over 1000 employees, the company works on some of the most
              well-known Adobe &nbsp;products worldwide, such as: Adobe Express,
              Adobe Experience Manager, Adobe Target, and &nbsp;Adobe Analytics.{" "}
            </span>
          </p>
          <p class="c16">
            <span class="c3">
              At Adobe, you’ll contribute to the creation of software products
              made to change the world and &nbsp;the way we work. &nbsp;
            </span>
          </p>
          <p class="c15">
            <span class="c3">
              From e-signs to successful movies and e-trade sites, Adobe creates
              products that cover a large &nbsp;variety of our customers’ needs.{" "}
            </span>
          </p>
          <p class="c8">
            <span class="c3">
              Whether you are a student or graduate, by joining us you’ll get to
              work together with our &nbsp;engineering teams on live products.
              Moreover, you’ll become a part of a community that cares
              &nbsp;about your perspectives and wants to hear your innovative
              ideas and advice.{" "}
            </span>
          </p>
          <p class="c4">
            <span class="c3">
              Diversity &amp; Inclusion are some of Adobe’s objectives. We
              invest in internal programs and events &nbsp;that create a
              comfortable and open environment for all our employees.
            </span>
          </p>
          <p class="c11">
            <span class="c3">
              Adobe Romania offers equal opportunities to all employees and
              supports their professional &nbsp;development. For us, this is
              what #adobelife means: a place where our colleagues can be
              &nbsp;themselves and express freely!{" "}
            </span>
          </p>
          <p class="c21">
            <span class="c3">
              If you want to find out more about our internship programs and
              career opportunities, check &nbsp;out the link below:{" "}
            </span>
          </p>
          <p class="c13">
            <a
              href="https://www.adobe.com/careers/university.html"
              target="_blank"
              class="c6"
            >
              https://www.adobe.com/careers/university.html
            </a>
          </p>
        </div>
      ),
    },
  },

  // compania doi
  {
    companyLogo: "/icons/systematic-icon.png",
    details: {
      title: "Systematic", // nume companie
      // descriere companie
      description: (
        <div class="c2 doc-content">
          <p class="c0">
            <span class="c1">
              At Systematic we believe that a line of code created with
              intelligence and innovation can advance societies, boost
              businesses, and change lives for the better.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              Systematic is an international software company with a
              Danish-inspired culture specialising in society-critical solutions
              across healthcare, defence, and library and learning.{" "}
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              When you join us, you become part of a worldwide community of
              dedicated colleagues. People driven by passion and the opportunity
              to truly make a difference – where it matters the most.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              Our culture is people-centric, and we thrive when working side by
              side. We value personality as much as professional competencies.
              So, while our teams are diverse and international, we are guided
              by the same values – no matter our role.
            </span>
          </p>
          <p class="c0">
            <span class="c1">
              In everything we do, we are driven by the desire to learn,
              collaborate, and change the world for the better. Together, we
              will never stop developing.
            </span>
          </p>
        </div>
      ),
    },
  },

  // compania trei
  {
    companyLogo: "/icons/sievo-icon.svg",
    details: {
      title: "Sievo", // nume companie
      // descriere companie
      description: (
        <div class="c4 doc-content">
          <p class="c1">
            <span class="c0">
              Meet Sievo, the leading software product company that specializes
              in procurement analytics. Our products and solutions are used by
              over 150 big-name companies like Levi's, Carlsberg, Unilever, and
              Johnson &amp; Johnson. We help our customers to make smarter,
              cost-efficient, and sustainable decisions when they purchase goods
              and services.{" "}
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              We started in Helsinki, Finland in 2003, and then, we've expanded
              to Chicago. In 2022, we proudly opened our third office right here
              in Bucharest.{" "}
            </span>
          </p>
          <p class="c1">
            <span class="c0">
              We believe in giving all Sievonians the autonomy they need to
              achieve greatness without constraints. Our Bucharest team embodies
              this spirit, and their rapid growth showcases their ability to
              exceed when given this freedom.
            </span>
          </p>
          <p class="c3">
            <span class="c2"></span>
          </p>
        </div>
      ),
    },
  },

  // compania patru
  {
    companyLogo: "/icons/n-able-icon.png",
    details: {
      title: "N-able", // nume companie
      // descriere companie
      description: (
        <div class="c2 doc-content">
          <span class="c3">
            N-able fuels IT services providers with powerful software solutions
            to monitor, manage, and secure their customers’ systems, data, and
            networks. We help partners excel at every stage of growth, protect
            their customers, and expand their offerings with an ever-increasing,
            flexible portfolio of integrations from leading technology
            providers.{" "}
          </span>
          <p>
            <span class="c3 c4">
              <a
                class="c0"
                href="https://www.google.com/url?q=http://n-able.com/&amp;sa=D&amp;source=editors&amp;ust=1695467362884387&amp;usg=AOvVaw3Nk6QmLIMseYebab-941sA"
                target="_blank"
              >
                n-able.com
              </a>
            </span>
          </p>
        </div>
      ),
    },
  },

  // compania cinci
  {
    companyLogo: "/icons/emia-icon.png",
    details: {
      title: "Emia", // nume companie
      // descriere companie
      description: (
        <div class="c8 doc-content">
          <p class="c5">
            <span class="c4">Cine suntem noi?</span>
          </p>
          <p class="c5">
            <span>Pentru inceput, </span>
            <span class="c0">noi</span>
            <span>&nbsp;suntem</span>
            <span>
              <a
                class="c1"
                href="https://www.google.com/url?q=http://emia.com/&amp;sa=D&amp;source=editors&amp;ust=1696063482838426&amp;usg=AOvVaw3z3WVTH_L7rYInQBtKEoVV"
              >
                &nbsp;
              </a>
            </span>
            <span class="c3">
              <a
                class="c1"
                href="https://www.google.com/url?q=http://emia.com/&amp;sa=D&amp;source=editors&amp;ust=1696063482838749&amp;usg=AOvVaw36qw8n7tujskUopX68Dpa7"
              >
                emia.com
              </a>
            </span>
            <span class="c2">
              , o companie cu o cultura antreprenoriala, care functioneaza
              asemenea unui hub IT multidisciplinar, avand ca domeniu principal
              de activitate dezvoltarea solutiilor integrate pentru online
              marketing. Business-ul nostru se bazeaza pe scripturi si
              algoritmi, infrastructura IT si solutii tehnologice, activand in
              exclusivitate pe piata internationala.{" "}
            </span>
          </p>
          <p class="c5">
            <span class="c2">
              Suntem o echipa aflata intr-o continua expansiune, formata in
              prezent din peste 100 de minti sclipitoare, care impart aceleasi
              valori: performanta, spirit de echipa, proactivitate si
              determinare. Ne place sa fim neconventionali si sa ne cream
              propriile reguli si procese, astfel incat sa ne bucuram de o
              atmosfera relaxata la birou si sa fim responsabili, fiecare in
              propriul stil.{" "}
            </span>
          </p>
          <p class="c6">
            <span class="c4">We work together. We grow together</span>
          </p>
          <p class="c6">
            <span class="c2">
              Vibe-ul nostru este concentrat pe oameni, deoarece credem ca
              succesul poate fi atins doar impreuna cu oamenii potriviti. De
              aceea, pe langa atractia pentru domeniul IT pe care o avem cu
              totii in comun, avem alaturi de noi personalitati diverse, colegi
              pasionati de arta, muzicieni, biciclisti, maratonisti,
              compozitori, terapeuti. Interesele diferite ne aduc mai aproape,
              ne extind orizonturile si ne ajuta sa legam prietenii frumoase.{" "}
            </span>
          </p>
          <p class="c5">
            <span class="c4">Ce trebuie sa retii despre noi?</span>
          </p>
          <p class="c5">
            <span class="c2">
              Suntem o echipa de profesionisti cu idei inovatoare, proactivi si
              cu o continua dorinta de evolutie. Industria in care activam, cea
              a marketing-ului digital, este intr-o permanenta schimbare, motiv
              pentru care trebuie sa fim la curent cu tot ce se intampla, pentru
              a putea fi mereu cu un pas inainte. Ne place sa ne organizam
              timpul astfel incat sa avem un echilibru intre viata personala si
              cea profesionala si avem grija ca atmosfera de lucru sa fie cat
              mai primitoare si relaxanta.
            </span>
          </p>
          <p class="c7">
            <span class="c2"></span>
          </p>
        </div>
      ),
    },
  },

  // compania sase
  {
    companyLogo: "/icons/4mayo-icon.png",
    details: {
      title: "4Mayo", // nume companie
      // descriere companie
      description: <span>Mai multe în curând</span>,
    },
  },
];
