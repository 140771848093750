import "./App.css";

import { Outlet } from "react-router-dom";


function App() {
  return <div className="mainContainer">  
          <Outlet />   
        </div>;
  
}

export default App;
