import React, { useRef, useState } from "react";
import styles from "./ProfilePage.module.css";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import CLCountdown from "../../../components/countdown/CLCountdown";
import Countdown from "react-countdown";
import QRCode from "react-qr-code";
import Axios from "axios";
import { signUpHackathon1Start, signUpHackathon1End } from "../../../staticData/dates/hackathonDates";
import { URL } from "../../../constants";
import { Slide, Snackbar, Alert } from "@mui/material";
import useStore from "../../../store/userStore";
 

registerPlugin(FilePondPluginFileValidateType);

function ProfilePage() {
  const [user] = useStore((state) => [state.user])
  
  const [CV, setCV] = useState(null);
  const [openToast, setOpenToast] = useState(false)

  const toastObj = useRef({
    type: "info",
    message: "",
  })

  const contentArray = [
    {
      title: "Job Hub",
      eventDate: "10 octombrie",
      signUpDates: "",
    },
    {
      title: "Workshop",
      eventDate: "25 octombrie",
      signUpDates: "",
    },
    {
      title: "Hackathon Adobe",
      eventDate: "28 octombrie",
      signUpDates: [signUpHackathon1Start, signUpHackathon1End],
    },
    {
      title: "Hackathon N-Able",
      eventDate: "28 octombrie",
      signUpDates: [signUpHackathon1Start, signUpHackathon1End],
    },
  ];

  const calculateTime = (startDate, endDate) => {
    const startTime = new Date(startDate).getTime();

    const now = new Date().getTime();

    const endTime = new Date(endDate).getTime();
    let timeDifference;
    let registerStarted;
    if (startTime >= now) {
      timeDifference = startTime - now;
      registerStarted = false;
    } else {
      timeDifference = endTime - now;
      registerStarted = true;
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );

    return startDate
      ? !registerStarted
        ? `Au rămas ${days} zile, ${hours} ore și ${minutes} minute până la înscrieri.`
        : `Înscrierile se termină în ${days} zile, ${hours} ore și ${minutes} minute`
      : "";
  };

  function updateCV(){
    const payload = new FormData();
    payload.append(`${user.firstName}_${user.lastName}`, CV);

    Axios.post(`${URL}/cv`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then(() => {
      toastObj.current.type = "success";
      toastObj.current.message = "CV-ul tău a fost schimbat cu succes";
    }).catch((error) => {
      console.log(error)

      toastObj.current.type = "error"
      toastObj.current.message = "Eroare de server la schimbarea CV-ului";
    }).finally(() => {
      setCV(null)
      setOpenToast(true)
    })
  }

  return (
    <div className={styles.profileContainer}>
      <div className={styles.profileCentralContainer}>
        <div className={styles.lowerSectionContainer}>
          <div className={styles.profileFirstCentralContainer}>
            <div>
              <FilePond
                files={CV}
                name={`${user.firstName}_${user.lastName}`}
                acceptedFileTypes={"application/pdf"}
                labelIdle="Schimbă CV"
                onupdatefiles={(fileItems) => {
                  if(fileItems && fileItems[0]){
                    setCV(fileItems[0].file);
                  }
                }}
                credits={false}
                dropOnPage
                dropValidation
                className={styles.changeCV}
                id={`${user.firstName}_${user.lastName}`}
              />
              {CV && (
                <div className={styles.containerSaveCVBtn}>
                  <button onClick={updateCV}>Salvează CV</button>
                </div>
              )}
            </div>

            <div className={styles.profileCareerLaunchTimer}>
              <CLCountdown />
            </div>
          </div>
          <div className={styles.eventsContainer}>
            {contentArray.map((event) => {
              return (
                <div className={styles.eventContainer} key={event.title}>
                  <div className={styles.contentEvent}>
                    <span className={styles.paragSpan}>
                      <p>
                        {event.title} <br />
                        <Countdown
                          className={styles.paragDate}
                          date={Date.now()}
                          renderer={calculateTime.bind(
                            null,
                            "",
                            ""
                            // commented because ITFest finished
                            // event.signUpDates[0],
                            // event.signUpDates[1]
                          )}
                        />
                      </p>
                    </span>
                    <p>{event.eventDate}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* Am comentat QR code-ul pana il testam si il facem sa mearga */}
        
        {/* <div className={styles.profileRightContainer}>
          <div className={styles.eventsRightContainer}></div>
          <div style={{ color: "white", fontSize: "1.8rem" }}>Codul tău QR</div>
          <div style={{ background: "white", padding: "16px" }}>
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={"Your QR CODE"}
              viewBox={`0 0 256 256`}
            />
          </div>
        </div> */}
      </div>
      <div
        style={{
          height: "auto",
          margin: "0 auto",
          maxWidth: 64,
          width: "100%",
        }}
      ></div>

        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={openToast}
          autoHideDuration={3000}
          onClose={() => setOpenToast(false)}
          TransitionComponent={Slide}
        >
          <Alert severity={toastObj.current.type}>
            {toastObj.current.message}
          </Alert>
      </Snackbar>
    </div>
  );
}

export default ProfilePage;
