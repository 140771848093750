export const clSystematic = {
  id: 12,
  linkTest:
    "https://docs.google.com/forms/d/e/1FAIpQLSdSXbweH_2To1CbnLEULS2RIH8GOwGqOR1G885IzCqGXZgVHw/viewform",
  companyLogo: "/icons/systematic-icon.png",
  companyName: "Systematic",
  period: [new Date("2023-10-06T00:00:00"), new Date("2023-10-13T00:00:00")],
  details: {
    title: "Career Launch Systematic",
    description: (
      <div class="c1 doc-content">
        <p class="c2">
          <span class="c0 c3">Are you ready for the Systematic challenge?</span>
        </p>
        <p class="c2">
          <span class="c0">
            Master key elements and nuances of Java by diving into core concepts
            covering Inheritance, Exception handling, and List data
            manipulation. Explore advanced topics on Threading, Heap memory
            management, String manipulation and object comparison.
          </span>
        </p>
      </div>
    ),
  },
  rules: {
    title: "Regulament Career Launch Systematic",
    text: (
      <div class="c7 c19 doc-content">
        <p class="c18 c21">
          <span class="c14 c7 c25"></span>
        </p>
        <ol class="c12 lst-kix_817og6txl1uf-0 start" start="1">
          <li class="c13 c23 c11 li-bullet-0">
            <h1 id="h.gjdgxs" style={{ display: "inline" }}>
              <span class="c8 c7">Organizator</span>
            </h1>
          </li>
        </ol>
        <p class="c5 c11">
          <span class="c4"></span>
        </p>
        <p class="c9">
          <span class="c3">
            Sindicatul Studenților din Facultatea de Cibernetică, Statistică și
            Informatică Economică (SiSC)
          </span>
          <span class="c1 c7">
            &nbsp;este cea mai veche organizație studențească din Academia de
            Studii Economice, fiind, totodată, și membru activ ANOSR.
          </span>
        </p>
        <p class="c20">
          <span class="c0">
            În cei 26 de ani de implicare, profesionalism și inovație, &nbsp;
          </span>
          <span class="c3">peste 200 de proiecte</span>
          <span class="c1 c7">&nbsp;au fost desfășurate cu succes.</span>
        </p>
        <p class="c9">
          <span class="c3">Misiunea SiSC</span>
          <span class="c0">&nbsp;cuprinde </span>
          <span class="c3">reprezentarea studenților</span>
          <span class="c0">
            &nbsp;din Facultatea de Cibernetică, Statistică și Informatică
            Economică,{" "}
          </span>
          <span class="c3">susținerea drepturilor studenților</span>
          <span class="c0">&nbsp;în mediul academic și </span>
          <span class="c3">dezvoltarea acestora</span>
          <span class="c0">&nbsp;</span>
          <span class="c3">atât pe plan personal, cât și profesional</span>
          <span class="c1 c7">, în cadrul proiectelor SiSC.</span>
        </p>
        <p class="c20 c21">
          <span class="c3 c14"></span>
        </p>
        <p class="c9">
          <span class="c0">
            Organizatorul proiectului ITFest și, implicit, al Career{" "}
          </span>
          <span class="c0">Launch-ului</span>
          <span class="c0">&nbsp;este </span>
          <span class="c3">
            Sindicatul Studenților din Facultatea de Cibernetică, Statistică și
            Informatică Economică (SiSC),
          </span>
          <span class="c1 c7">
            &nbsp;denumit în continuare „Organizator”, în parteneriat cu
            Facultatea de Cibernetică, Statistică și Informatică Economică.
          </span>
        </p>
        <p class="c20">
          <span class="c1 c7">
            Evenimentul se va derula cu respectarea prevederilor cuprinse în
            prezentul regulament (denumit în cele ce urmează „Regulamentul”).
            Termenii și condițiile prezentului Regulament, așa cum sunt
            prezentate mai jos, sunt obligatorii pentru toți participanții
            evenimentului.
          </span>
        </p>
        <p class="c9">
          <span class="c1 c7">
            Organizatorul își rezervă dreptul de a modifica Regulamentul,
            aducând la cunoștința publicului acest fapt în timp util.
          </span>
        </p>
        <p class="c6">
          <span class="c1 c7"></span>
        </p>
        <ol class="c12 lst-kix_817og6txl1uf-0" start="2">
          <li class="c13 c23 c11 li-bullet-0">
            <h1 id="h.g051z0whfne1" style={{ display: "inline" }}>
              <span class="c8 c7">Descrierea proiectului ITFest</span>
            </h1>
          </li>
        </ol>
        <p class="c5">
          <span class="c4"></span>
        </p>
        <p class="c9">
          <span class="c0">
            ITFest este un proiect cu tradiție, aflat la cea de
          </span>
          <span class="c2">-a </span>
          <span class="c16">XVI-a ediție</span>
          <span class="c3">.</span>
          <span class="c0">
            &nbsp;Prin intermediul diverselor evenimente, ITFest își propune să
            contribuie la diminuarea unor slăbiciuni ale sistemului educațional,
            precum: lipsa contextelor în care tinerii se pot afirma în fața
            angajatorilor, imposibilitatea tinerilor de a dobândi experiența
            necesară angajării,{" "}
          </span>
          <span class="c0">neadaptarea</span>
          <span class="c1 c7">
            &nbsp;sistemului educațional la nevoile pieței muncii sau existența
            unui caracter preponderent teoretic al sistemului educațional.
          </span>
        </p>
        <p class="c9">
          <span class="c0">
            Evenimentul se dezvoltă în jurul sferei IT și cuprinde{" "}
          </span>
          <span class="c3">Job Hub, Workshop-ul</span>
          <span class="c0">&nbsp;tematic, </span>
          <span class="c3">Hackathon</span>
          <span class="c0">&nbsp;si </span>
          <span class="c3">Career Launch</span>
          <span class="c0">. </span>
          <span class="c0">
            Ediția din acest an se va desfășura în perioada
          </span>
          <span class="c2">&nbsp;</span>
          <span class="c16">3</span>
          <span class="c14 c16">-28 octombrie.</span>
        </p>
        <p class="c20 c21">
          <span class="c14 c16 c17"></span>
        </p>
        <ol class="c12 lst-kix_817og6txl1uf-0" start="3">
          <li class="c13 c11 c23 li-bullet-0">
            <h1 id="h.30j0zll" style={{ display: "inline" }}>
              <span class="c8 c7">Aspecte organizatorice</span>
            </h1>
          </li>
        </ol>
        <p class="c6">
          <span class="c4 c10"></span>
        </p>
        <p class="c6">
          <span class="c4 c10"></span>
        </p>
        <p class="c20">
          <span class="c2">
            &nbsp;Career Launch-ul va avea loc în perioada{" "}
          </span>
          <span class="c16">6-28 octombrie 2023</span>
          <span class="c2">&nbsp;și se va </span>
          <span class="c2">desfășura</span>
          <span class="c2">&nbsp;</span>
          <span class="c2">în</span>
          <span class="c2">&nbsp;două etape. </span>
        </p>
        <p class="c9">
          <span class="c0">Acestea vor fi organizate</span>
          <span class="c3">&nbsp;în forma</span>
          <span class="c3">t fizic</span>
          <span class="c0">&nbsp;</span>
          <span class="c0">
            și se vor desfășura în conformitate cu prevederile prezentului
            Regulament, fiind accesibile oricărei persoane fizice înscrise
            într-un program de studii preuniversitare (liceu) sau universitare,
          </span>
          <span class="c0 c26">&nbsp;</span>
          <span class="c1 c7">
            care acceptă termenii și condițiile prezentului Regulament.
          </span>
        </p>
        <p class="c20 c21">
          <span class="c1 c7"></span>
        </p>
        <ol class="c12 lst-kix_817og6txl1uf-0" start="4">
          <li class="c13 c23 c11 li-bullet-0">
            <h1 id="h.1fob9te" style={{ display: "inline" }}>
              <span class="c8 c7">Dreptul de participare</span>
            </h1>
          </li>
        </ol>
        <p class="c5">
          <span class="c4"></span>
        </p>
        <p class="c9">
          <span class="c1 c7">
            Poate participa orice persoană fizică înscrisă într-un program de
            studii preuniversitare (liceu) sau universitare.
          </span>
        </p>
        <p class="c9">
          <span class="c0">
            Pentru a putea participa la Career Launch-ului , persoanele trebuie
            să se înscrie prin sustinerea testelor tehnice{" "}
          </span>
          <span class="c0">aflate </span>
          <span class="c0">pe site-ul </span>
          <span class="c3 c24">
            <a
              class="c15"
              href="https://www.google.com/url?q=https://itfest.sisc.ro/&amp;sa=D&amp;source=editors&amp;ust=1696535803788311&amp;usg=AOvVaw2V99_wVbu4nth51n_tbbde"
            >
              https://itfest.sisc.ro/
            </a>
          </span>
          <span class="c1 c7">
            &nbsp; și să își încarce CV-ul în cadrul formularului.
          </span>
        </p>
        <p class="c20 c21">
          <span class="c1 c7"></span>
        </p>
        <ol class="c12 lst-kix_817og6txl1uf-0" start="5">
          <li class="c13 c23 c11 li-bullet-0">
            <h1 id="h.2et92p0" style={{ display: "inline" }}>
              <span class="c8 c7">
                &nbsp;Înscrierea și selectarea participanților
              </span>
            </h1>
          </li>
        </ol>
        <p class="c5">
          <span class="c4"></span>
        </p>
        <p class="c9">
          <span class="c16">Înscrierea</span>
          <span class="c2">&nbsp;persoanelor la Career Launch </span>
          <span class="c2">se va face între &nbsp;6-12 octombrie.</span>
          <span class="c2">&nbsp;</span>
          <span class="c0">
            Doritorii trebuie să se înscrie completând testele tehnice aflate pe
            site-ul{" "}
          </span>
          <span class="c3 c24">
            <a
              class="c15"
              href="https://www.google.com/url?q=https://itfest.sisc.ro/&amp;sa=D&amp;source=editors&amp;ust=1696535803788849&amp;usg=AOvVaw1SMFrPJvaRUBM6vBO8Y2dU"
            >
              https://itfest.sisc.ro/
            </a>
          </span>
          <span class="c0">&nbsp;și </span>
          <span class="c3">să își încarce CV-ul</span>
          <span class="c1 c7">
            &nbsp;în cadrul formularului. După această dată nu se vor mai
            accepta alte aplicații. Înscrișii au obligația ca soluția oferită la
            un test tehnic să fie &nbsp;strict munca lor și să nu fie copiată
            dintr-un anume loc. Cei care s-au înscris declară expres că au
            citit, au înțeles și sunt de acord cu termenii și condițiile
            prezentului Regulament. Nu se percep taxe de înscriere.
          </span>
        </p>
        <p class="c6">
          <span class="c1 c7"></span>
        </p>
        <p class="c20 c21">
          <span class="c1 c7"></span>
        </p>
        <ol class="c12 lst-kix_817og6txl1uf-0" start="6">
          <li class="c13 c23 c11 li-bullet-0">
            <h1 id="h.w8uvkfcfx367" style={{ display: "inline" }}>
              <span class="c8">Obligațiile Participanților</span>
            </h1>
          </li>
        </ol>
        <p class="c5">
          <span class="c4"></span>
        </p>
        <p class="c9">
          <span class="c0">
            Prezentele obligații trebuie respectate pe durata perioadei Career{" "}
          </span>
          <span class="c0">Launch-ului</span>
          <span class="c1 c7">
            , precum și după finalizarea sa. Participanții își asumă:
          </span>
        </p>
        <ul class="c12 lst-kix_cy3n47ufr8k-0 start">
          <li class="c11 c13 li-bullet-0">
            <span class="c1 c7">
              să nu furnizeze public informații care pot aduce daune imaginii
              Organizatorului;
            </span>
          </li>
          <li class="c13 c11 li-bullet-0">
            <span class="c1 c7">
              să păstreze un comportament decent și să respecte indicațiile
              Organizatorului;
            </span>
          </li>
          <li class="c13 c11 li-bullet-0">
            <span class="c1 c7">
              să își asigure singuri modalitățile de acces la evenimente
              (laptop, conexiune la internet etc.);
            </span>
          </li>
          <li class="c13 c11 li-bullet-0">
            <span class="c1 c7">
              să nu înregistreze și să difuzeze conținutul Career Launch-ului;
            </span>
          </li>
          <li class="c13 c11 li-bullet-0">
            <span class="c0">ca</span>
            <span class="c0">
              &nbsp;materialele obținute de către Organizator în urma
              evenimentelor (fotografiile și înregistrările video și audio) să
              fie făcute publice pe site-ul{" "}
            </span>
            <span class="c3">
              <a
                class="c15"
                href="https://www.google.com/url?q=http://itfest.sisc.ro&amp;sa=D&amp;source=editors&amp;ust=1696535803790004&amp;usg=AOvVaw1kEznWwCRzKdjKYKhs3Kz7"
              >
                http://itfest.sisc.ro
              </a>
            </span>
            <span class="c0">, pe Instagram la </span>
            <span class="c3">
              <a
                class="c15"
                href="https://www.google.com/url?q=https://www.instagram.com/sisc.ase/?hl%3Dro&amp;sa=D&amp;source=editors&amp;ust=1696535803790265&amp;usg=AOvVaw3jJhnh63qXND05UWWZql3U"
              >
                @sisc.ase
              </a>
            </span>
            <span class="c0">, pe paginile de Facebook </span>
            <span class="c3">
              <a
                class="c15"
                href="https://www.google.com/url?q=https://www.facebook.com/it.fest.sisc&amp;sa=D&amp;source=editors&amp;ust=1696535803790642&amp;usg=AOvVaw07qwY-npD_sM-DhmAX4pUk"
              >
                ITFest
              </a>
            </span>
            <span class="c0">&nbsp;și </span>
            <span class="c3">
              <a
                class="c15"
                href="https://www.google.com/url?q=https://www.facebook.com/sisc.ase&amp;sa=D&amp;source=editors&amp;ust=1696535803790781&amp;usg=AOvVaw3qxQrKMAJOudkqEojONgu9"
              >
                SiSC ASE
              </a>
            </span>
            <span class="c1 c7">.</span>
          </li>
        </ul>
        <p class="c9">
          <span class="c1 c7">
            Participanții precum și orice persoană care va fi prezentă pe
            perioada desfășurării evenimentelor înțeleg, sub sancțiunea
            suportării tuturor consecințelor generate de încălcarea acestor
            obligații.
          </span>
        </p>
        <p class="c20 c21">
          <span class="c1 c7"></span>
        </p>
        <ol class="c12 lst-kix_817og6txl1uf-0" start="7">
          <li class="c13 c23 c11 li-bullet-0">
            <h1 id="h.udy6dsyho3oe" style={{ display: "inline" }}>
              <span class="c8">Confidențialitatea datelor</span>
            </h1>
          </li>
        </ol>
        <p class="c5">
          <span class="c4"></span>
        </p>
        <p class="c9">
          <span class="c1 c7">
            Organizatorul are următoarele obligații în ceea ce privește
            confidențialitatea datelor:{" "}
          </span>
        </p>
        <ul class="c12 lst-kix_x2o889oeo7mz-0 start">
          <li class="c13 c11 li-bullet-0">
            <span class="c0">respectarea și protejarea </span>
            <span class="c3">confidențialității datelor personale</span>
            <span class="c0">&nbsp;</span>
            <span class="c0">din</span>
            <span class="c1 c7">&nbsp;formularele de înscriere;</span>
          </li>
          <li class="c13 c11 li-bullet-0">
            <span class="c1 c7">
              prelucrarea și folosirea informațiilor personale conform politicii
              de confidențialitate a datelor personale;
            </span>
          </li>
          <li class="c13 c11 li-bullet-0">
            <span class="c1 c7">
              informațiile personale nu vor fi folosite în niciun mod care
              contravine politicii menționate anterior;
            </span>
          </li>
          <li class="c13 c11 li-bullet-0">
            <span class="c1 c7">
              materialele trimise de Participanți nu vor fi folosite în alte
              scopuri ce nu țin de eveniment.
            </span>
          </li>
        </ul>
        <p class="c9">
          <span class="c1 c7">
            Organizatorul își rezervă dreptul de a oferi partenerilor CV-urile
            Participanților.
          </span>
        </p>
        <p class="c20 c21">
          <span class="c1 c7"></span>
        </p>
        <ol class="c12 lst-kix_817og6txl1uf-0" start="8">
          <li class="c13 c23 c11 li-bullet-0">
            <h1 id="h.3dy6vkm" style={{ display: "inline" }}>
              <span class="c8 c7">Dreptul de autor</span>
            </h1>
          </li>
        </ol>
        <p class="c5">
          <span class="c4"></span>
        </p>
        <p class="c9">
          <span class="c0">
            Prezentările lucrărilor sau a oricăror alte materiale realizate în
            vederea concursului{" "}
          </span>
          <span class="c3">nu vor rămâne în proprietatea Organizatorului</span>
          <span class="c1 c7">.</span>
        </p>
        <p class="c9">
          <span class="c1 c7">
            Organizatorul nu își asumă nicio răspundere în cazul în care
            lucrările realizate în urma concursului folosesc elemente de
            conținut (texte, imagini, coduri sursă etc.) care încalcă drepturile
            de autor. Întreaga răspundere aparține celui care a realizat
            lucrarea.
          </span>
        </p>
        <p class="c20 c21">
          <span class="c1 c7"></span>
        </p>
        <ol class="c12 lst-kix_817og6txl1uf-0" start="9">
          <li class="c13 c23 c11 li-bullet-0">
            <h1 id="h.2s8eyo1" style={{ display: "inline" }}>
              <span class="c8 c7">Litigii</span>
            </h1>
          </li>
        </ol>
        <p class="c5">
          <span class="c4"></span>
        </p>
        <p class="c9">
          <span class="c0">
            Eventualele litigii apărute între Organizator și Participant se vor
            rezolva pe cale{" "}
          </span>
          <span class="c3">amiabilă</span>
          <span class="c1 c7">.</span>
        </p>
        <p class="c9">
          <span class="c0">
            În cazul în care un Participant are de semnalat un incident în
            legătură cu desfășurarea evenimentului, se va adresa Organizatorului
            cu o{" "}
          </span>
          <span class="c3">sesizare scrisă</span>
          <span class="c0">&nbsp;în acest sens trimisă pe adresa </span>
          <span class="c3">itfest@sisc.ro</span>
          <span class="c1 c7">
            , în termen de maximum 15 zile de la data incidentului,
            Organizatorul fiind obligat să ofere un răspuns în maximum 30 de
            zile.
          </span>
        </p>
        <p class="c9">
          <span class="c2">Organizarea proiectului </span>
          <span class="c16">poate fi întreruptă</span>
          <span class="c1">
            &nbsp;doar în cazul apariţiei unor evenimente importante neprevăzute
            sau din motive independente de voinţa Organizatorilor.
          </span>
        </p>
        <p class="c20 c21">
          <span class="c1"></span>
        </p>
        <ol class="c12 lst-kix_817og6txl1uf-0" start="10">
          <li class="c13 c23 c11 li-bullet-0">
            <h1 id="h.35nkun2" style={{ display: "inline" }}>
              <span class="c8">Dispoziții finale</span>
            </h1>
          </li>
        </ol>
        <p class="c5">
          <span class="c4"></span>
        </p>
        <p class="c9">
          <span class="c2">Prezentul Regulament este valabil pentru </span>
          <span class="c16">ediția din anul 2023</span>
          <span class="c1">
            &nbsp;a proiectului ITFest și are caracter obligatoriu, atât pentru
            Organizator, cât și pentru Participanți.{" "}
          </span>
        </p>
        <p class="c9">
          <span class="c2">
            Alte acţiuni întreprinse de Organizator şi nereglementate de către
            regulament, vor fi analizate de ambele părţi spre un{" "}
          </span>
          <span class="c16">acord comun</span>
          <span class="c1">
            , atât al Participanţilor, cât şi al Organizatorilor, astfel încât
            aceste acţiuni să nu pună în pericol desfăşurarea normală a
            proiectului.
          </span>
        </p>
        <p class="c9">
          <span class="c2">
            Prezentul Regulament este disponibil în format online pe site-ul{" "}
          </span>
          <span class="c16 c24">
            <a
              class="c15"
              href="https://www.google.com/url?q=https://itfest.sisc.ro/&amp;sa=D&amp;source=editors&amp;ust=1696535803793264&amp;usg=AOvVaw3IcsXttpu_zyYMqsrThqQ7"
            >
              https://itfest.sisc.ro/
            </a>
          </span>
          <span class="c1">&nbsp;. </span>
        </p>
        <p class="c5">
          <span class="c4"></span>
        </p>
        <p class="c5">
          <span class="c4"></span>
        </p>
      </div>
    ),
  },
};
