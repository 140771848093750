import React from 'react'
import styles from "./AchievementCard.module.css"
import AboutQuestButton from './AboutQuestButton'

function AchievementCard({ children, eventData }) {
  const { aboutQuest,  companyLogo } = eventData

  return (
    <div className={styles.cardContainer}>
      <div className={styles.innerCardContainer} style={{
        backgroundImage: `url(${companyLogo})`,
      }}>
        <div className={styles.aboutQuestContainer}>
          {eventData.aboutQuest && <AboutQuestButton title={eventData.title} aboutQuest={aboutQuest} />}
        </div>
      </div>

      {children}
    </div>
  )
}

export default AchievementCard