import React, { useRef } from 'react'

import styles from "./Rules.module.css"
import { Button } from '@mui/material'
import CustomModal from '../modal/CustomModal'

function Rules({ rules }) {
    const modalOpenRef = useRef()

  return (
    <>
    <Button 
    ref={modalOpenRef}
    variant="contained" 
    sx={{
    width: "130px",
    backgroundColor: "#074A29",
    borderRadius: "1.5rem",
    '&:hover': {
      backgroundColor: "#0E7E42",
    },
    }}>Regulament</Button>

    <CustomModal elementRef={modalOpenRef} title={rules.title}>
      <p className={styles.rulesContent}>{rules.text}</p>
    </CustomModal>
    </>
  )
}

export default Rules