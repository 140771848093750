import React from 'react'
import styles from "./CLCountdown.module.css"
import Countdown from 'react-countdown'

import { technicalTestsStart, technicalTestsEnd } from '../../staticData/dates/careerLaunchDates'

function CLCountdown() {
    const stopwatch = ({ days, hours, minutes }) => {
        return (
            <div className={styles.countdownContainer}>
                <p className={styles.timerDays}>{days} zile</p>
                <p className={styles.timerHours}>{hours} ore</p>
                <p className={styles.timerMinutes}>{minutes} minute</p>
            </div>
        )
    }

    const timerTo = Date.now() < technicalTestsStart ? technicalTestsStart : technicalTestsEnd 

  return (
    <div>
        <h3 className={styles.headerCL}>Career Launch</h3>
        <p className={styles.announcementCL}>{Date.now() >= technicalTestsStart ? "Timp rămas" : "Începe în:"}</p>
        <Countdown 
        date={timerTo}
        renderer={stopwatch}
        />
    </div>
  )
}

export default CLCountdown