export const registerRules = {
  lastName: {
    required: {
      value: true,
      message: "Numele trebuie completat",
    },
    minLength: {
      value: 3,
      message: "Numele trebuie să aibă minim 3 caractere",
    },
  },
  firstName: {
    required: {
      value: true,
      message: "Prenumele trebuie completat",
    },
    minLength: {
      value: 3,
      message: "Prenumele trebuie să aibă minim 3 caractere",
    },
  },
  email: {
    required: {
      value: true,
      message: "Emailul trebuie completat",
    },
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: "Email invalid",
    },
  },
  password: {
    required: {
      value: true,
      message: "Parola trebuie completată",
    },
    minLength: {
      value: 8,
      message: "Parola trebuie să aibă minim 8 caractere",
    },
  },
  phoneNumber: {
    required: {
      value: true,
      message: "Numărul de telefon trebuie completat",
    },
    pattern: {
      value: /^[0-9]{10}$/,
      message: "Număr de telefon invalid",
    },
  },
  university: {
    required: {
      value: true,
      message: "Universitatea trebuie completată",
    },
    minLength: {
      value: 3,
      message: "Universitatea trebuie să aibă minim 3 caractere",
    },
  },
  year: {
    required: {
      value: true,
      message: "Anul de studiu trebuie completat",
    },
  },
  faculty: {
    required: {
      value: true,
      message: "Specializarea trebuie completată",
    },
    minLength: {
      value: 3,
      message: "Specializarea trebuie să aibă minim 3 caractere",
    },
  },
};
