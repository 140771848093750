import { create } from "zustand";

const useStore = create((set) => ({
  user: null,
  eventsAttended: {},
  setUser: (user) => {
    set({ user });
  },
  setEventsAttended: (eventsAttended) => {
    set({ eventsAttended });
  },
  addEventAttended: (eventID) => {
    set((state) => ({
      eventsAttended: { ...state.eventsAttended, [eventID]: true },
    }));
  },
}));

export default useStore;
