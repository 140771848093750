import React from 'react'
import styles from './DashboardPage.module.css'
import AttendanceStatus from '../../../components/attendanceStatus/AttendanceStatus'

function DashboardPage() {
  return (
    <div>
    <div className={styles.dashboardEvents}>
      <h1>JobHub</h1>
      <AttendanceStatus eventID={2} />
    </div>

    <div className={styles.dashboardEvents}>
      <h1>Career Launch</h1>
      <AttendanceStatus eventID={2} />
    </div>

    <div className={styles.dashboardEvents}>
      <h1>Workshop</h1>
      <AttendanceStatus eventID={2} />
    </div>

    <div className={styles.dashboardEvents}>
      <h1>Hackathon</h1>
      <AttendanceStatus eventID={2} />
    </div>
   </div>

    
  )
}

export default DashboardPage