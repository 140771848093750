import React, { useRef } from 'react'

import styles from "./Details.module.css"
import { Button } from '@mui/material'
import CustomModal from '../modal/CustomModal'

function Details({ details }) {
  const openModalRef = useRef()

  return (
    <div>
      <Button
      ref={openModalRef}
      variant="contained" 
      sx={{
        width: "130px",
        backgroundColor: "#074A29",
        borderRadius: "1.5rem",
        '&:hover': {
          backgroundColor: "#0E7E42",
        },
      }}>Detalii</Button>

      <CustomModal elementRef={openModalRef} title={details.title}>
        <p className={styles.detailsContent}>{details.description}</p>
      </CustomModal>
    </div>
  )
}

export default Details