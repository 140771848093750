import React, { useState } from "react";
// Uncomment this and the use of it below, and install the package so it works
// import { QrReader } from "react-qr-reader";
import styles from "./QRPage.module.css";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const options = [
  {
    value: "JobHub", //TO BE CHANGED IN PROD AFTER EVENTS ARE IN DATABASE !!
    label: "JobHub",
  },
  {
    value: "Workshop",
    label: "Workshop",
  },
  {
    value: "Hackathon",
    label: "Hackathon",
  },
  {
    value: "Hackathon 2",
    label: "Hackathon 2",
  },
];

const selectStyles = {
  ".MuiSelect-select": {
    border: "1px solid var(--dark-green) !important",
  },

  "& .MuiFormLabel-root": {
    color: "var(--dark-green)", // Customize text color
  },

  "& .MuiInputBase-input": {
    color: "var(--dark-green)", // Customize text color
  },
  "& .MuiInput-underline:before": {
    borderBottomColor: "green", // Customize the border color
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "red", // Customize the border color when focused
  },
};

const QRPage = () => {
  const [data, setData] = useState("");

  return (
    <>
      <div className={styles.containerQRPage}>
        <div style={{ height: "640px", width: "420px" }}>
          {/* <QrReader
            onResult={(result, error) => {
              if (!!result) {
                setData(result?.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
          /> */}
          <p style={{ textAlign: "center", marginBottom: "2.5rem" }}>{data}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <TextField
              id="outlined-select-currency"
              select
              defaultValue="JobHub"
              label="Select"
              helperText="Please select your currency"
              sx={selectStyles}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {data.length > 0 && (
              <button className={styles.QRPageButton}> Validate</button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default QRPage;
