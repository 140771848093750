import { hackathonRules } from "../../pages/user-pages/hackathon/hackathonRules";

export const hackathonNable = {
  id: 32,
  companyLogo: "/icons/n-able-icon.png",
  details: {
    title: "Hackathon N-able",
    description: (
      <div class="c3 doc-content">
        <p class="c0">
          <span class="c1">SMART CITIES</span>
        </p>
        <p class="c0">
          <span class="c2">
            A "Smart city" is a city that uses technology to tie together
            communities, the natural environment and how people interact. The
            journey that communities and people must take to reach this goal is
            long and full of cultural, engineering, and natural challenges, but
            together we can reach our destination! Through our hackathon we
            would like to close the gap and shorten the journey. You will be
            using technology to increase quality of life, mobility, prosperity,
            efficiency of urban operations and services or any other solution
            that can get us closer to our goal in the century of speed. When you
            help us reach our “Smart City” destination, you can win big: 1st
            prize is{" "}
          </span>
          <span class="c2 c5">2400</span>
          <span class="c5 c2">&nbsp;</span>
          <span class="c5 c2">RON</span>
          <span class="c2">, 2nd prize is </span>
          <span class="c5 c2">2100 RON</span>
          <span class="c2">&nbsp;and 3rd prize is </span>
          <span class="c5 c2">1800 RON</span>
          <span class="c2 c4">!</span>
        </p>
        <p class="c6">
          <span class="c4 c7"></span>
        </p>
      </div>
    ),
  },
  rules: {
    title: "Regulament Hackathon",
    text: hackathonRules,
  },
};
