export const workshopDescription = (
  <div class="c1 doc-content">
    <p class="c2">
      <span class="c0">
        &nbsp;&nbsp;&nbsp;&nbsp;Într-o eră digitală ce este în continuă
        evoluție, alege să fii arhitectul propriei tale transformări! Aici nu
        doar că vei învăța tainele tehnologiei, ci vei și construi punți către
        viitorul pe care îl creezi.
      </span>
    </p>
    <p class="c2">
      <span>
        &nbsp;&nbsp;&nbsp;&nbsp;Fă-ți loc în calendar pentru a-ți hrăni
        curiozitatea și a te îmbarca într-o călătorie captivantă a cunoașterii!
        {/* Te așteptăm pe 25 octombrie, la ora 18:00, la sediul companiei Adobe
        pentru a te lăsa descoperIT! */}
      </span>
    </p>
    <p class="c3">
      <span class="c0"></span>
    </p>
  </div>
);
