import React, { useEffect, useState } from 'react'

import styles from "./CustomModal.module.css"

import { Modal } from '@mui/material'

function CustomModal({ children, elementRef, title }) {
    const [modalOpen, setModalOpen] = useState(false)

    function handleModalOpen(){
        setModalOpen(true)
    }

    function handleModalClose(){
      setModalOpen(false)
    }

    useEffect(() => {
        if(elementRef.current){
            elementRef.current.addEventListener("click", handleModalOpen)
        }

        return () => {
            if (elementRef.current) {
                elementRef.current.removeEventListener("click", handleModalOpen);
            }
        };
    }, [])

  return (
    <Modal
    open={modalOpen}
    onClose={handleModalClose}
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}
    >
      <div className={styles.modalContainer}>
        <h4 className={styles.modalHeader}>{title}</h4>
        {children}
      </div>
  </Modal>
  )
}

export default CustomModal