import React from "react";
import useStore from "../store/userStore";
import { Outlet, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import GreetMessage from "../components/GreetMessage/GreetMessage";

function RoleBasedAccess({ isAdmin, WrapperComponent }) {
  const [user] = useStore((state) => [state.user]);
  const navigate = useNavigate();

  useEffect(() => {
    if (user === null) {
      navigate("/");
    } else if (user.isAdmin !== isAdmin) {
      if (user.isAdmin === false) {
        navigate("profile");
      } else {
        navigate("dashboard");
      }
    }
  }, [user]);

  if (user !== null) {
    return (
      <WrapperComponent>
        <GreetMessage />
        <Outlet />
      </WrapperComponent>
    );
  }
}

export default RoleBasedAccess;
