import React, { useEffect, useState } from 'react'

import styles from "./EventStatus.module.css"

function EventStatus({children, eventTitle, startDate, endDate, messageStart, messageEnd}) {
  const [nowDate, setNowDate] = useState(new Date())

  // Odata la 5 minute, verific statusul evenimentului
  useEffect(() => {
    const intervalID = setInterval(() => {
      setNowDate(new Date())
    }, 300000)

    return () => clearInterval(intervalID)
  }, [])

  if(nowDate < startDate){
    return <h2><span className={styles.eventTitle}>{eventTitle}</span> {messageStart}</h2>
  }

  if(nowDate > endDate){
    return <h2><span className={styles.eventTitle}>{eventTitle}</span> {messageEnd}</h2>
  }

  return <>{children}</>
}

export default EventStatus