import React from 'react'

import { Button } from '@mui/material'
import { Link } from "react-router-dom"
 
function ToTechnicalTest({ testLink, companyName, period }) {
  const currentDate = new Date()
  const disabledStatus = period[0] > currentDate || period[1] < currentDate ? true : false

  return (
      <Button
        variant="contained"
        disabled={disabledStatus}
        sx={{
            color: "var(--black)",
            backgroundColor: "#FFAD4A",
            borderRadius: "1.5rem",
            '&:hover': {
                backgroundColor: "#f99b28",
            },
            "&.Mui-disabled": {
              backgroundColor: "lightgray",
            },
        }}>
          <Link to={testLink} target='_blank' style={{color: "black", fontFamily: "inherit"}}>
            Test tehnic {companyName.slice(0, 3)}.
          </Link>
      </Button>
  )
}



export default ToTechnicalTest