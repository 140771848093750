import { workshopRules } from "../../pages/user-pages/workshop/workshopRules";

export const workshopCompanyData = {
  companyLogo: "/icons/adobe-icon.png",
  details: {
    title: "Workshop Adobe", // nume companie
    // descriere companie
    description: <p>Mai multe în curând</p>,
  },
  rules: {
    title: "Regulament Workshop",
    text: workshopRules,
  },
};
