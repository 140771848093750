import React from 'react'

import styles from "./GiveawayStep.module.css"

import { Link } from 'react-router-dom'

function GiveawayStep({ giveaway }) {
  return (
        <div className={styles.stepContainer}>
            <span className={styles.step}>{giveaway.step}</span>
            
            <p>
                {giveaway.text}
                {giveaway.link && <Link className={styles.link} to={giveaway.link.href} target="_blank">{giveaway.link.text}</Link>}
            </p>
        </div>
  )
}

export default GiveawayStep