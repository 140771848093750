export const hackathonDescription = (
  <div class="c8 doc-content">
    <p class="c11">
      <span class="c5 c3 c10"></span>
    </p>
    <p class="c1">
      <span class="c3">
        &nbsp;&nbsp;&nbsp;&nbsp;Dacă dorești să arăți că ești as în programare,
        că scrii cod cu viteza luminii sau, pur și simplu, vrei{" "}
      </span>
      <span class="c3">să îți</span>
      <span class="c9 c3">
        &nbsp;testezi limitele, ITFest îți sare în ajutor! În cadrul acestui
        concurs de programare în stil maraton, vei avea ocazia să dezvolți
        alături de prietenii tăi o aplicație pe o temă propusă de una dintre
        companiile partenere.
      </span>
    </p>
    <p class="c1">
      <span class="c3">
        &nbsp;&nbsp;&nbsp;&nbsp;Vor fi organizate două Hackathoane, pe
      </span>
      <span class="c3">&nbsp;data de </span>
      <span class="c0">28 octombrie</span>
      <span class="c3">
        , fiecare având o tematică diferită. Acestea se vor desfășura în{" "}
      </span>
      <span class="c0">format fizic</span>
      <span class="c3">, la sediul companiilor sau </span>
      <span class="c0">online</span>
      <span class="c3">. Te poți înscrie la hackathon într-o echipă de </span>
      <span class="c0">2 - 4 persoane.</span>
    </p>

    <p class="c3 c9">Cele mai bune aplicații vor fi premiate:</p>
    <ul class="c2 lst-kix_45ffdc5vabml-0 start">
      <li class="c6 li-bullet-0">
        <span class="c9 c3">locul 1: 2400 lei</span>
      </li>
      <li class="c6 li-bullet-0">
        <span class="c9 c3">locul 2: 2100 lei</span>
      </li>
      <li class="c6 li-bullet-0">
        <span class="c9 c3">locul 3: 1800 lei</span>
      </li>
    </ul>
  </div>
);
