export const hackathonRules = (
  <div class="c3 c26 doc-content">
    <ol class="c6 lst-kix_ecefw3mly16d-0 start" start="1">
      <li class="c11 c23 li-bullet-0">
        <h1 id="h.gjdgxs">
          <span class="c15">Organizator</span>
        </h1>
      </li>
    </ol>
    <p class="c9 c8">
      <span class="c5">
        Sindicatul Studenților din Facultatea de Cibernetică, Statistică și
        Informatică Economică (SiSC)
      </span>
      <span class="c0">
        &nbsp;este cea mai veche organizație studențească din Academia de Studii
        Economice, fiind, totodată, și membru activ ANOSR.
      </span>
    </p>
    <p class="c9">
      <span class="c4">În cei </span>
      <span class="c4">27 </span>
      <span class="c4">
        de ani de implicare, profesionalism și inovație, &nbsp;
      </span>
      <span class="c5">peste </span>
      <span class="c5">220 </span>
      <span class="c5">de proiecte</span>
      <span class="c0">&nbsp;au fost desfășurate cu succes.</span>
    </p>
    <p class="c9 c8">
      <span class="c5">Misiunea SiSC</span>
      <span class="c4">&nbsp;cuprinde </span>
      <span class="c5">reprezentarea studenților</span>
      <span class="c4">
        &nbsp;din Facultatea de Cibernetică, Statistică și Informatică
        Economică,{" "}
      </span>
      <span class="c5">susținerea drepturilor studenților</span>
      <span class="c4">&nbsp;în mediul academic și </span>
      <span class="c5">dezvoltarea acestora</span>
      <span class="c4">&nbsp;</span>
      <span class="c5">atât pe plan personal, cât și profesional</span>
      <span class="c0">, în cadrul proiectelor SiSC.</span>
    </p>
    <p class="c1 c8">
      <span class="c0"></span>
    </p>
    <p class="c8 c9">
      <span class="c4">
        Organizatorul proiectului ITFest și, implicit, al Maratonului de
        programare (denumit în continuare „Concursul”) este{" "}
      </span>
      <span class="c5">Sindicatul Studenților din Cibernetică (SiSC),</span>
      <span class="c0">
        &nbsp;denumit în continuare Organizator”, în parteneriat cu Facultatea
        de Cibernetică, Statistică și Informatică Economică.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c0">
        Concursul se va derula cu respectarea prevederilor cuprinse în prezentul
        regulament (denumit în cele ce urmează „Regulamentul”). Termenii și
        condițiile prezentului Regulament, așa cum sunt prezentate mai jos, sunt
        obligatorii pentru toți participanții Concursului.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c0">
        Organizatorul își rezervă dreptul de a modifica Regulamentul, aducând la
        cunoștința publicului acest fapt în timp util.
      </span>
    </p>
    <p class="c1">
      <span class="c0"></span>
    </p>
    <ol class="c6 lst-kix_ecefw3mly16d-0" start="2">
      <li class="c10 li-bullet-0">
        <span class="c3 c19">Descrierea proiectului ITFest</span>
      </li>
    </ol>
    <p class="c9 c8">
      <span class="c4">ITFest este un proiect cu tradiție, aflat la cea </span>
      <span class="c4 c3">de-</span>
      <span class="c4 c3">a</span>
      <span class="c5 c3">&nbsp;XVI-a ediție.</span>
      <span class="c4">
        &nbsp;Prin intermediul diverselor evenimente, ITFest își propune să
        contribuie la diminuarea unor slăbiciuni ale sistemului educațional,
        precum: lipsa contextelor în care tinerii se pot afirma în fața
        angajatorilor, imposibilitatea tinerilor de a dobândi experiența
        necesară angajării,{" "}
      </span>
      <span class="c4">neadaptarea</span>
      <span class="c0">
        &nbsp;sistemului educațional la nevoile pieței muncii sau existența unui
        caracter preponderent teoretic al sistemului educațional.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c4">
        Evenimentul se dezvoltă în jurul sferei IT și cuprinde{" "}
      </span>
      <span class="c5">Job Hub</span>
      <span class="c4">, </span>
      <span class="c5">Career Launch</span>
      <span class="c4">, </span>
      <span class="c5 c3">W</span>
      <span class="c5 c3">orkshop </span>
      <span class="c4 c3">tematic </span>
      <span class="c4 c3">și </span>
      <span class="c5 c3">Hackathon</span>
      <span class="c0 c3">.</span>
    </p>
    <p class="c9 c8">
      <span class="c4">Ediți</span>
      <span class="c4">a din acest an se va desfășura în perioada </span>
      <span class="c5">6</span>
      <span class="c5">-28 octombrie</span>
      <span class="c4">. </span>
    </p>
    <p class="c1">
      <span class="c16 c5 c3"></span>
    </p>
    <ol class="c6 lst-kix_ecefw3mly16d-0" start="3">
      <li class="c10 li-bullet-0">
        <span class="c15">Durata și aria de desfășurare</span>
      </li>
    </ol>
    <p class="c9 c8">
      <span class="c4 c3">
        Vor fi 4 hackathoane, 2 dintre ele pe 21 octombrie, respectiv alte 2 pe
      </span>
      <span class="c4 c3">&nbsp;</span>
      <span class="c4 c3">28 octombrie și vor dura 12 ore fiecare. </span>
      <span class="c4 c3">
        Concursurile, două pe zi, vor avea loc în data de{" "}
      </span>
      <span class="c5 c3">21 și 28 octombrie</span>
      <span class="c4 c3">, sub forma unor Maratoane de programare (</span>
      <span class="c5 c3">Hackathon</span>
      <span class="c4 c3">) cu o durată de </span>
      <span class="c5 c3">12 ore</span>
      <span class="c4 c3">.</span>
    </p>
    <p class="c9 c8">
      <span class="c4 c3">Concursurile sunt organizate și </span>
      <span class="c4 c3">se vor desfășura fizic,</span>
      <span class="c4 c3">
        &nbsp;în conformitate cu prevederile prezentului Regulament, fiind
        accesibil oricărei persoane fizice, înrolată într-un program de liceu,
        facultate sau master,
      </span>
      <span class="c4 c3 c24">&nbsp;</span>
      <span class="c0 c3">
        care acceptă termenii și condițiile prezentului Regulament.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c4">Concurenţii </span>
      <span class="c5">nu au voie să converseze </span>
      <span class="c0">
        cu nimeni în afară de membrii echipei lor şi personalul desemnat de
        către organizatori. Personalul de asistenţă poate sfătui participanţii
        în legătură cu probleme de sistem, precum explicarea mesajelor de eroare
        ale sistemului.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c4">Concurenții pot fi </span>
      <span class="c5">descalificați</span>
      <span class="c4">
        &nbsp;de către organizatori pentru orice activitate care periclitează
        competiţia, cum ar fi modificarea neautorizată a materialelor de concurs
        sau comportamentul de distragere a atenţiei.
      </span>
    </p>
    <p class="c1">
      <span class="c0 c3"></span>
    </p>
    <ol class="c6 lst-kix_ecefw3mly16d-0" start="4">
      <li class="c10 li-bullet-0">
        <span class="c15">Dreptul de participare</span>
      </li>
    </ol>
    <p class="c9 c8">
      <span class="c0">
        Poate participa orice persoană fizică, înrolată într-un program de
        liceu, facultate sau master.{" "}
      </span>
    </p>
    <p class="c9 c8">
      <span class="c4">
        În cazul în care un participant este minor, este obligatorie prezența la
        eveniment cu{" "}
      </span>
      <span class="c5">acordul parental</span>
      <span class="c0">
        &nbsp;(anexat la sfârșitul Regulamentului) completat de unul dintre
        părinții sau tutorii legali ai minorului.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c4">
        Prin participarea la Concursuri, participanții, precum și orice persoană
        care va fi prezentă pe perioada desfășurării Concursurilor,{" "}
      </span>
      <span class="c5">își dau acordul</span>
      <span class="c4">
        &nbsp;pentru a fi înregistrați video și/sau audio și/sau foto și pentru
        a li se face publice fotografiile și/sau înregistrările video şi/sau
        audio
      </span>
      <span class="c4 c3">pe site-ul </span>
      <span class="c5 c3">
        <a
          class="c14"
          href="https://www.google.com/url?q=http://itfest.sisc.ro&amp;sa=D&amp;source=editors&amp;ust=1695065038047650&amp;usg=AOvVaw1SKRGqUYTttwVpX9aP7l52"
        >
          http://itfest.sisc.ro
        </a>
      </span>
      <span class="c4 c3">, pe Instagram la </span>
      <span class="c5 c3">
        <a
          class="c14"
          href="https://www.google.com/url?q=https://www.instagram.com/sisc.ase/?hl%3Dro&amp;sa=D&amp;source=editors&amp;ust=1695065038047800&amp;usg=AOvVaw11Mm9B7eDzShhOcKUhWrjH"
        >
          @sisc.ase
        </a>
      </span>
      <span class="c4 c3">, pe paginile de Facebook </span>
      <span class="c3 c5">
        <a
          class="c14"
          href="https://www.google.com/url?q=https://www.facebook.com/it.fest.sisc&amp;sa=D&amp;source=editors&amp;ust=1695065038047904&amp;usg=AOvVaw3XJjbm6se1iTCqasHINO3H"
        >
          ITFest
        </a>
      </span>
      <span class="c4 c3">&nbsp;și </span>
      <span class="c5 c3">
        <a
          class="c14"
          href="https://www.google.com/url?q=https://www.facebook.com/sisc.ase&amp;sa=D&amp;source=editors&amp;ust=1695065038047996&amp;usg=AOvVaw2hoV7HEIp21gBAm-W9tCb8"
        >
          SiSC ASE
        </a>
      </span>
      <span class="c0">. </span>
    </p>
    <p class="c9 c8">
      <span class="c0">
        Prin participarea la Concursuri, participanții precum și orice persoană
        care va fi prezentă pe perioada desfășurării concursurilor înțeleg să nu
        furnizeze public informații care pot aduce daune imaginii
        Organizatorului, sub sancțiunea suportării tuturor consecințelor
        generate de încălcarea acestei obligații. Prezenta obligație trebuie
        respectată pe durata Concursurilor, precum și după finalizarea sa.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c0">
        Participanții își vor aduce echipamentele de care au nevoie pentru
        dezvoltarea aplicației lor.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c4">
        Participanții care nu respectă prezentul Regulament și deciziile
        oficiale ale juriului, partenerilor și Organizatorului, vor fi excluși
        automat din concurs.
      </span>
    </p>
    <p class="c1">
      <span class="c0 c12"></span>
    </p>
    <ol class="c6 lst-kix_ecefw3mly16d-0" start="5">
      <li class="c10 li-bullet-0">
        <span class="c15">Înscrierea și selectarea participanților</span>
      </li>
    </ol>
    <p class="c9 c8">
      <span class="c4">
        Pentru a putea participa la unul dintre Maratoanele de programare,
        participantul trebuie să se înscrie completând{" "}
      </span>
      <span class="c5">formularul de înregistrare</span>
      <span class="c4">&nbsp;aflat pe site-ul </span>
      <span class="c5 c3">https://itfest.sisc.ro/</span>
      <span class="c0 c3">
        &nbsp;și să își încarce CV-ul în cadrul formularului.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c0">Nu se percep taxe de înscriere.</span>
    </p>
    <p class="c9 c8">
      <span class="c5">Înscrierea</span>
      <span class="c4">&nbsp;participanților se va face în perioada </span>
      <span class="c5">9-16 octombrie, respectiv 16-23 octombrie</span>
      <span class="c4">.</span>
      <span class="c4">&nbsp;</span>
      <span class="c4">
        După această dată nu se vor mai accepta alte înscrieri - excepție face
        situația în care Organizatorul decide să aibă loc prelungiri ale
        înscrierilor.
      </span>
      <span class="c0">
        &nbsp;Participanții au obligația să furnizeze informații reale și
        complete în formularul de înscriere în concurs.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c0">
        Participanții se pot înscrie doar la una dintre categoriile stabilite de
        organizator.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c4">Participanții se pot înscrie în </span>
      <span class="c5">echipe formate din 1-3 membri</span>
      <span class="c0">
        . Membrii acesteia își vor alege un reprezentant care va completa primul
        formularul de înscriere. Ulterior, este necesară completarea
        formularului de înscriere și de către ceilalți membri ai echipei, pe
        baza codului token primit de către reprezentant la înscrierea acestuia.
      </span>
    </p>
    <p class="c2">
      <span class="c0">
        Prin înscriere participantul declară expres că a citit, a înțeles și
        este de acord cu termenii și condițiile prezentului Regulament.
      </span>
    </p>
    <p class="c2">
      <span class="c4 c3">
        Imposibilitatea de a participa la oricare dintre evenimentele desemnate
        va duce la{" "}
      </span>
      <span class="c5 c3">descalificarea automată.</span>
    </p>
    <p class="c22 c18">
      <span class="c0"></span>
    </p>
    <ol class="c6 lst-kix_ecefw3mly16d-0" start="6">
      <li class="c11 c21 li-bullet-0">
        <span class="c15">Confidențialitatea datelor</span>
      </li>
    </ol>
    <p class="c9 c8">
      <span class="c4">Organizatorul respectă și protejează </span>
      <span class="c5">confidențialitatea datelor personale</span>
      <span class="c4">&nbsp;</span>
      <span class="c4">din</span>
      <span class="c0">
        &nbsp;formularele de înscriere. Orice informație personală este
        prelucrată și folosită conform politicii de confidențialitate a datelor
        personale și nu va fi folosită în niciun mod care contravine acestei
        politici.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c0">
        Organizatorul are obligația de a nu folosi materialele trimise de
        participanți în alte scopuri ce nu țin de concurs. Totodată,
        organizatorul își rezervă dreptul de a oferi partenerilor CV-urile
        participanților.
      </span>
    </p>
    <p class="c1">
      <span class="c0"></span>
    </p>
    <ol class="c6 lst-kix_ecefw3mly16d-0" start="7">
      <li class="c10 li-bullet-0">
        <span class="c15">Dreptul de autor</span>
      </li>
    </ol>
    <p class="c9 c8">
      <span class="c4">
        Prezentările lucrărilor sau orice alte materiale realizate în vederea
        concursului{" "}
      </span>
      <span class="c5">nu vor rămâne în proprietatea organizatorului</span>
      <span class="c4">, dar ne rezervăm </span>
      <span class="c5">dreptul de a ceda codurile sursă</span>
      <span class="c0">
        &nbsp;ale participanților în cazul în care acestea sunt solicitate de
        către partenerii proiectului.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c4">Aplicațiile dezvoltate de fiecare participant </span>
      <span class="c5">nu pot fi utilizate/comercializate</span>
      <span class="c0">
        &nbsp;de către participanți sau de către companiile partenere, fără
        acordul scris al fiecărei părți implicate .
      </span>
    </p>
    <p class="c9 c8">
      <span class="c0">
        Organizatorul nu își asumă nicio răspundere în cazul în care lucrările
        realizate în urma concursului folosesc elemente de conținut (texte,
        imagini, coduri sursă etc.) care încalcă drepturile de autor. Întreaga
        răspundere aparține celui care a realizat lucrarea.
      </span>
    </p>
    <p class="c1">
      <span class="c0"></span>
    </p>
    <ol class="c6 lst-kix_ecefw3mly16d-0" start="8">
      <li class="c10 li-bullet-0">
        <span class="c15">Jurizarea</span>
      </li>
    </ol>
    <p class="c2">
      <span class="c4">Participanții au dreptul de a fi </span>
      <span class="c4">jurizați</span>
      <span class="c0">
        &nbsp;în mod echitabil, în aceleași condiții și după aceleași criterii
        în funcție de fiecare categorie.
      </span>
    </p>
    <p class="c2">
      <span class="c4 c3">Juriul este format din </span>
      <span class="c5 c3">reprezentanții companiilor</span>
      <span class="c0 c3">
        &nbsp;partenere. Criteriile de jurizare sunt alese exclusiv de aceștia.
      </span>
    </p>
    <p class="c2">
      <span class="c4">Ierarhia se stabilește în </span>
      <span class="c5">ordinea descrescătoare a punctajelor</span>
      <span class="c0">&nbsp;obținute.</span>
    </p>
    <p class="c2">
      <span class="c4">
        Membrii juriului nu sunt obligați să acorde premii la o secțiune, în
        cazul în care lucrările din secțiunea respectivă nu sunt la un nivel
        calitativ satisfăcător.
      </span>
    </p>
    <p class="c2">
      <span class="c0">
        După ce concursul s-a încheiat, iar rezultatele au fost făcute publice,
        participanții pot depune plângeri sau contestații, după cum urmează:
      </span>
    </p>
    <p class="c18 c22">
      <span class="c0"></span>
    </p>
    <p class="c2">
      <span class="c5">Imediat după finalizarea concursului </span>
      <span class="c0">
        concurenții pot depune o plângere organizatorilor, urmând ca aceștia să
        ia o decizie finală.
      </span>
    </p>
    <p class="c1">
      <span class="c0"></span>
    </p>
    <p class="c9 c8">
      <span class="c0">Acest proces este guvernat după cum urmează:</span>
    </p>
    <ul class="c6 lst-kix_523cz1qx1eb0-0 start">
      <li class="c9 c11 li-bullet-0">
        <span class="c0">
          Rezultatele concursului nu sunt finale, până când procesul de
          reclamații și contestații nu s-a finalizat.
        </span>
      </li>
      <li class="c9 c11 li-bullet-0">
        <span class="c4">
          Orice altă reclamație trebuie să se bazeze pe una sau mai multe din
          următoarele situații: &nbsp;încălcări ale regulamentului, abaterea
          unei echipe sau abateri ale unor oficiali ai concursului cu intenția
          de a{" "}
        </span>
        <span class="c4">frauda</span>
        <span class="c0">.</span>
      </li>
      <li class="c9 c11 li-bullet-0">
        <span class="c0">
          Doar participanții pot depune reclamații și contestații.
        </span>
      </li>
      <li class="c9 c11 li-bullet-0">
        <span class="c0">
          Deciziile sunt definitive. Mai exact, o decizie privind rezolvarea
          unei probleme nu poate fi contestată.
        </span>
      </li>
    </ul>
    <p class="c1">
      <span class="c0"></span>
    </p>
    <p class="c9 c8">
      <span class="c0">
        Recursul va fi respins în mod automat în cazul în care procedura de mai
        sus nu este urmată.
      </span>
    </p>
    <p class="c1">
      <span class="c0"></span>
    </p>
    <ol class="c6 lst-kix_ecefw3mly16d-0" start="9">
      <li class="c10 li-bullet-0">
        <span class="c15">Premii acordate</span>
      </li>
    </ol>
    <p class="c9 c8">
      <span class="c4 c3">
        În cadrul Concursului, organizatorul oferă câte{" "}
      </span>
      <span class="c5 c3">3 (trei) premii</span>
      <span class="c4 c3">&nbsp;</span>
      <span class="c4 c3">pentru fiecare categorie. </span>
      <span class="c4 c3">Premiile reprezintă </span>
      <span class="c5 c3">vouchere în valoare de 2400 le</span>
      <span class="c4 c3">i (locul I), </span>
      <span class="c5 c3">2100 lei</span>
      <span class="c4 c3">&nbsp;(locul II), respectiv </span>
      <span class="c5 c3">1800 lei</span>
      <span class="c4 c3">&nbsp;(premiul III).</span>
    </p>
    <p class="c9 c8">
      <span class="c0 c3">
        Participanții câștigători vor fi contactați ulterior de către
        Organizator pentru a intra în posesia premiilor.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c0 c3">
        Organizatorul nu va fi ținut răspunzător pentru toate și oricare
        prejudicii, suferite de către câștigător, indiferent de natura acestor
        prejudicii, suferite din momentul predării premiului către câștigător.
      </span>
    </p>
    <p class="c1 c8">
      <span class="c0 c3"></span>
    </p>
    <ol class="c6 lst-kix_ecefw3mly16d-0" start="10">
      <li class="c10 li-bullet-0">
        <span class="c15">Litigii</span>
      </li>
    </ol>
    <p class="c9 c8">
      <span class="c0">
        Eventualele litigii apărute între organizator și concurenți se vor
        rezolva pe cale amiabilă.
      </span>
    </p>
    <p class="c9 c8">
      <span class="c4">
        În cazul în care un concurent are de semnalat un incident în legătură cu
        desfășurarea evenimentului, se va adresa organizatorului cu o{" "}
      </span>
      <span class="c5">sesizare scrisă</span>
      <span class="c4">&nbsp;în acest sens trimisă pe adresa </span>
      <span class="c5">itfest@sisc.ro</span>
      <span class="c0">
        , în termen de maximum 15 de zile de la data incidentului, organizatorul
        fiind obligat să ofere un răspuns în maximum 30 de zile.
      </span>
    </p>
    <p class="c1 c8">
      <span class="c0"></span>
    </p>
    <ol class="c6 lst-kix_ecefw3mly16d-0" start="11">
      <li class="c11 c20 li-bullet-0">
        <h1 id="h.olcjsnoy7cvc">
          <span class="c15">Anexă</span>
        </h1>
      </li>
    </ol>
    <p class="c7">
      <span class="c16 c5">&nbsp;</span>
    </p>
    <p class="c7">
      <span class="c16 c5">Declarație pe propria răspundere</span>
    </p>
    <p class="c7 c18">
      <span class="c16 c5"></span>
    </p>
    <p class="c9">
      <span class="c0">
        Subsemnatul(a)___________ domiciliat în (oraș, strada, număr)
        ______________ identificat cu CI/BI (serie, număr) ____________, CNP
        _________, în calitate de părinte/reprezentant legal al minorului
        _________________ născut(ă) la data de (zz/ll/aaaa): _____/ ______ /
        ______, localitatea ________, județul_______________,
      </span>
    </p>
    <p class="c9">
      <span class="c0">
        declar pe propria răspundere că minorul este apt din punct de vedere
        psihic, fizic și medical să participe la evenimentul Hackathon din
        cadrul proiectului ITFest, organizat de Sindicatul Studenților din
        Facultatea de Cibernetică, Statistică și Informatică Economică (SiSC) la
        București, pe data de 21 sau 28 octombrie 2023.
      </span>
    </p>
    <p class="c9">
      <span class="c0">
        Am luat la cunoștință despre toate aspectele legate de participarea
        efectivă la competiție, ce reies din Regulamentul de organizare.
      </span>
    </p>
    <p class="c9">
      <span class="c0">
        În sensul celor mai de sus, îmi asum întreaga responsabilitate pentru
        orice vătămare sau prejudiciu ce i-ar putea fi cauzate minorului, ca
        urmare a participării la competiție.
      </span>
    </p>
    <p class="c9">
      <span class="c0">&nbsp;</span>
    </p>
    <p class="c1">
      <span class="c0"></span>
    </p>
    <p class="c1">
      <span class="c0"></span>
    </p>
    <p class="c9">
      <span class="c4">
        Semnătura, &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      </span>
      <span class="c4">Data</span>
      <span class="c0">,</span>
    </p>
    <p class="c1">
      <span class="c0"></span>
    </p>
    <p class="c9">
      <span class="c0">
        _______________________ &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;_____________
      </span>
    </p>
    <p class="c1">
      <span class="c0"></span>
    </p>
    <p class="c18 c25">
      <span class="c13"></span>
    </p>
  </div>
);
