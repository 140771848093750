import React, { useRef, useState } from 'react'

import styles from "./HackathonSignUp.module.css"

import { Alert, Button, FormControl, InputLabel, MenuItem, Select, Slide, Snackbar, TextField } from '@mui/material'
import CustomModal from '../../modal/CustomModal'
import { Controller, useFieldArray, useForm } from 'react-hook-form'

import Axios from "axios"
import { URL } from '../../../constants'
import useStore from '../../../store/userStore'

function HackathonSignUp({ eventID, title, signedUpHackathonID, setSignedUpHackathonID }) {
    const openModalRef = useRef()

    const [user, eventsAttended, addEventAttended] = useStore((state) => [state.user, state.eventsAttended, state.addEventAttended])

    const [openToast, setOpenToast] = useState(false)
    const toastObj = useRef({
      type: "info",
      message: ""
    })

    const { control, formState: { errors }, handleSubmit, getValues } = useForm({
      defaultValues: {
        teamName: "",
        noMembers: ""
      },
      mode: "onChange",
      delayError: 1500
    })
    const { fields, append, remove } = useFieldArray({
      control,
      name: "membersIDs"
    })

    function validID(memberID, index){
      const membersIDs = getValues("membersIDs")

      if(index === 0 && Number(membersIDs[0].memberID) !== user.id){
        return "Id-ul tău trebuie pus pe prima poziție"
      }

      for(let i = 0; i < membersIDs.length; i++){
        if(i === index){
          continue
        }

        if(memberID === membersIDs[i].memberID){
          return "Nu poți înscrie un membru de mai multe ori"
        }
      }
    }

    function onSubmit(formData){
      let membersIDsVal = []
      for(const memberObj of formData.membersIDs){
        membersIDsVal.push(memberObj.memberID)
      }

      Axios.post(`${URL}/hackathon`, {
        eventId: eventID,
        name: formData.teamName,
        membersIDs: membersIDsVal
      }).then(() => {    
        setSignedUpHackathonID(eventID)
        addEventAttended(eventID)
      }).catch((error) => {
        console.log(error)
        toastObj.current.type = "error"
        toastObj.current.message = error.response.data.message

        setOpenToast(true)
      })
    }

  return (
    <>
      {
        eventsAttended[eventID] ? 
        <p className={styles.signedUp}>Ești înscris</p> 
        :
        <Button
        ref={openModalRef}
        variant="contained"
        disabled={signedUpHackathonID !== null && signedUpHackathonID !== eventID ? true : false}
        sx={{
            backgroundColor: "#1A9A32",
            borderRadius: "1.5rem",
            '&:hover': {
              backgroundColor: "#136B1E",
            },

            '&.Mui-disabled': {
              backgroundColor: "lightgray",
            }
        }}>Înscrie-te
        </Button> 
      }

      <Snackbar 
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={openToast}
      autoHideDuration={3000}
      onClose={() => setOpenToast(false)}
      TransitionComponent={Slide}
      >
        <Alert severity={toastObj.current.type}>{toastObj.current.message}</Alert>
      </Snackbar>

      <CustomModal elementRef={openModalRef} title={`Înscriere ${title}`}>
        {signedUpHackathonID !== null ? 
          <div className={styles.signedUpContainer}>
            <p>Echipa {getValues("teamName")} a fost înscrisă cu succes</p>
            <img src="/icons/check-icon.svg" alt="team-signed-up" className={styles.checkIcon} />
          </div>
          :
          <form onSubmit={handleSubmit(onSubmit)} className={styles.formContainer}>
          <Controller 
          control={control}
          name="teamName"
          rules={{
            required: "Numele echipei este obligatoriu",
            minLength: {
              value: 6,
              message: "Numele echipei trebuie sa contina cel putin 6 caractere"
            }
          }}
          render={({ field }) => {
            return(
              <div className={styles.formItem}>
                <TextField {...field} label="Nume echipa" variant="outlined" fullWidth 
              InputLabelProps={{
                style: {
                  color: "white",
                },
              }}
              sx={{
                input: {
                  color: "white",
                },
                "& .MuiOutlinedInput-root": {
                  width: "100%",
                  "& fieldset": {
                    borderColor: "#399345",
                  },
                },
              }}
              />
              <p className={styles.inputError}>{errors.teamName && errors.teamName.message}</p>
              </div>
            )
          }}
          />

          <Controller
            control={control}
            name="noMembers"
            rules={{
              required: "Numărul de membrii trebuie selectat"
            }}
            render={({ field }) => {
              return (
                <div className={styles.formItem}>
                  <FormControl fullWidth>
                    <InputLabel id="numar-membrii" 
                    style={{
                      color: "white"
                    }}
                    >Număr membrii</InputLabel>
                    <Select
                      value={field.value}
                      variant="outlined"
                      labelId="numar-membrii"
                      label="Număr membrii"
                      onChange={(event) => {
                        const noMembers = event.target.value
                        const prevNoMembers = field.value

                        let diffMembers = noMembers - prevNoMembers

                        if(diffMembers > 0){
                          for(let i = prevNoMembers; i < noMembers; i++){
                            append({ memberID: "" })
                          }
                        }else{
                          for(let i = prevNoMembers; i > noMembers; i--){
                            remove(i - 1)
                          }
                        }

                        field.onChange(noMembers)
                      }}
                      sx={{
                        "& .MuiSelect-icon": {
                          color: "#399345",
                        },
                        color: "white",
                        "& fieldset": {
                          borderColor: "#399345",
                        },
                      }}
                    >
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="3">3</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                    </Select>
                  </FormControl>

                  <p className={styles.inputError}>{errors.noMembers && errors.noMembers.message}</p>
                </div>
              );
            }}
          />

          {fields.map((item, index) => {
            return (
              <div key={item.id} className={styles.formItem}>
                <Controller 
                control={control}
                name={`membersIDs[${index}].memberID`}
                rules={{
                  required: `${index > 0 ? `ID-ul membrului ${index + 1} trebuie completat` : "ID-ul tău trebuie completat"}`,
                  validate: (memberID) => validID(memberID, index)
                }}
                render={({ field }) => {
                  return (
                  <TextField
                  value={field.value.memberID}
                  onChange={field.onChange}
                  label={`${index > 0 ? `ID membru ${index + 1}`: "ID-ul tău" }`} variant="outlined" fullWidth 
                  InputLabelProps={{
                  style: {
                    color: "white",
                  },
                  }}
                  sx={{
                    input: {
                      color: "white",
                  },
                  "& .MuiOutlinedInput-root": {
                    width: "100%",
                    "& fieldset": {
                      borderColor: "#399345",
                    },
                  },
                }}
                />
                  )
                }}
              />
                <p className={styles.inputError}>{errors.membersIDs && errors.membersIDs[index] && errors.membersIDs[index].memberID.message}</p>
              </div>
            )
          })}

          <Button type="submit" variant="outlined"
          sx={{
            backgroundColor: "#399345",
            color: "white",
            borderRadius: "1rem",
            marginTop: "3vh",
            padding: "10px 40px",
            "&:hover": {
              backgroundColor: "#0b7619",
            },
          }}
          >Înscrie echipa</Button>
      </form>
        }
      </CustomModal>
    </>
  )
}

export default HackathonSignUp