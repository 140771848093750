import React from 'react'

import styles from "./InputError.module.css"

function InputError({ errorObj, field }) {
    return (
        <p className={styles.inputError}>{errorObj[field] && errorObj[field].message}</p>
     )
}

export default InputError