export const jobHubDescription = (
  <div class="c1 doc-content">
    <p class="c0">
      <span class="c2">
        &nbsp;&nbsp;&nbsp;&nbsp;Îți dorești un job și nu știi exact de unde să
        începi procesul?{" "}
      </span>
      <span class="c3">ITFest</span>
      <span class="c2">&nbsp;sare în ajutorul tău! Vino la </span>
      <span class="c3">Job Hub</span>
      <span class="c2">, </span>
      <span class="c2">târgul de joburi</span>
      <span class="c2">
        &nbsp;dedicat studenților, pentru a afla mai multe detalii{" "}
      </span>
      <span class="c2">d</span>
      <span class="c4 c2">
        espre oportunitățile de angajare existente în cadrul companiilor
        partenere.{" "}
      </span>
    </p>
    <p class="c0">
      <span class="c2">&nbsp;&nbsp;&nbsp;&nbsp;Te așteptăm pe data de </span>
      <span class="c3">10 octombrie</span>
      <span class="c2">&nbsp;între orele </span>
      <span class="c3">11:00</span>
      <span class="c2">&nbsp;și </span>
      <span class="c3">17:00 </span>
      <span class="c2">la </span>
      <span class="c3">
        Facultatea de Cibernetică, Statistică și Informatică Economică
      </span>
      <span class="c2">, pe holurile etajelor 1 și 2, </span>
      <span class="c2 c4">pentru a te lăsa descoperIT!</span>
    </p>
  </div>
);
