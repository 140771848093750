import React, { useRef, useState } from "react";
import styles from "./SignUpPage.module.css";

import FormContainer from "../../../components/form/FormContainer";

import { useForm, FormProvider, Controller } from "react-hook-form";
import {
  Alert,
  Button,
  MenuItem,
  Select,
  Slide,
  Snackbar,
  InputLabel,
  FormControl,
  Checkbox,
} from "@mui/material";
import FormInput from "../../../components/form/FormInput";

import { registerRules } from "./registerRules";

import "filepond/dist/filepond.min.css";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import Axios from "axios";
import { GDPRText } from "./GDPRText";
import CustomModal from "../../../components/modal/CustomModal";
import useStore from "../../../store/userStore";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../constants";

registerPlugin(FilePondPluginFileValidateType);

function SignUpPage() {
  const methods = useForm({
    defaultValues: {
      lastName: "",
      firstName: "",
      email: "",
      password: "",
      phoneNumber: "",
      university: "",
      faculty: "",
      year: "",
      CV: null,
      GDPR: false,
    },
  });

  const { getValues } = methods;
  const firstName = getValues("firstName")
  const lastName = getValues("lastName")

  const [setUser] = useStore((state) => [state.setUser]);
  const navigate = useNavigate();
  const [invalidFields, setInvalidFields] = useState(false);

  const openModalRef = useRef();

  let errorMessage = useRef("");
  function onError() {
    errorMessage.current = "Există câmpuri cu erori";
    setInvalidFields(true);
  }

  function onSubmit(formData) {
    Axios.post(`${URL}/user`, formData)
      .then((userDB) => {
        setUser(userDB.data);

        navigate("/profile");
        localStorage.setItem("user", JSON.stringify(formData));
      }).then(() => {
        const payload = new FormData()
        payload.append(`${formData.firstName}_${formData.lastName}`, formData.CV)

        Axios.post(`${URL}/cv`, payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
      })
      .catch((error) => {
        if (error.response.data.errorList) {
          errorMessage.current = error.response.data.errorList[0]
        setInvalidFields(true)

        } else {
          errorMessage.current = "Eroare necunoascuta";
          console.log(error)
        }

      });
  }

  return (
    <div className={styles.signUpContainer}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={invalidFields}
        autoHideDuration={3000}
        onClose={() => setInvalidFields(false)}
        TransitionComponent={Slide}
      >
        <Alert severity="error">{errorMessage.current}</Alert>
      </Snackbar>

      <FormContainer linkUrl="/">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit, onError)}>
            <FormInput
              inputName="lastName"
              rules={registerRules.lastName}
              placeholderText="Nume (fără diacritice!)"
            />
            <FormInput
              inputName="firstName"
              rules={registerRules.firstName}
              placeholderText="Prenume (fără diacritice!)"
            />
            <FormInput
              inputName="email"
              rules={registerRules.email}
              placeholderText="Email"
            />
            <FormInput
              inputName="password"
              rules={registerRules.password}
              placeholderText="Parola (fără diacritice!)"
            />
            <FormInput
              inputName="phoneNumber"
              rules={registerRules.phoneNumber}
              placeholderText="Număr de telefon"
            />
            <FormInput
              inputName="university"
              rules={registerRules.university}
              placeholderText="Universitate (fără diacritice!)"
            />

            <FormInput
              inputName="faculty"
              rules={registerRules.faculty}
              placeholderText="Facultate (fără diacritice!)"
            />

            <Controller
              control={methods.control}
              name="year"
              rules={registerRules.year}
              render={({ field }) => {
                return (
                  <FormControl
                    fullWidth
                    sx={{
                      marginBottom: "15px",
                    }}
                  >
                    <InputLabel
                      id="an-studiu"
                      sx={{
                        color: "#399345",
                      }}
                    >
                      Anul de studiu
                    </InputLabel>
                    <Select
                      variant="outlined"
                      labelId="an-studiu"
                      label="Anul de studiu"
                      value={field.value}
                      onChange={field.onChange}
                      error={methods.formState.errors.year ? true : false}
                      sx={{
                        "& .MuiSelect-icon": {
                          color: "#399345",
                        },
                        color: "#399345",
                        "& fieldset": {
                          borderColor: "#399345",
                        },
                      }}
                    >
                      <MenuItem value="l1">Anul I</MenuItem>
                      <MenuItem value="l2">Anul II</MenuItem>
                      <MenuItem value="l3">Anul III</MenuItem>
                      <MenuItem value="l4">Anul IV</MenuItem>
                      <MenuItem value="m1">Master I</MenuItem>
                      <MenuItem value="m2">Master II</MenuItem>
                      <MenuItem value="f">Proaspăt absolvent</MenuItem>
                    </Select>
                  </FormControl>
                );
              }}
            />
            <p className={styles.inputError}>
              {methods.formState.errors.year &&
                methods.formState.errors.year.message}
            </p>

            <div className={styles.containerCV}>
              <Controller
                control={methods.control}
                name="CV"
                rules={{
                  required: "CV-ul trebuie încărcat",
                }}
                render={({ field }) => (
                  <FilePond
                    name={`${firstName}_${lastName}`}
                    acceptedFileTypes={"application/pdf"}
                    labelIdle="Încarcă CV"
                    onupdatefiles={(fileItems) => {
                      if (fileItems && fileItems[0]) {
                        field.onChange(fileItems[0].file)
                      }
                    }}
                    credits={false}
                    dropOnPage
                    dropValidation
                    className={styles.changeCV}
                    id={`${firstName}_${lastName}`}
                  />
                )}
              />
              <p className={styles.inputError}>
                {methods.formState.errors.CV &&
                  methods.formState.errors.CV.message}
              </p>
            </div>

            <Controller
              control={methods.control}
              name="GDPR"
              rules={{
                validate: (GDPRChecked) => {
                  if (GDPRChecked === false) {
                    return "Regulamentul GDPR trebuie acceptat pentru a-ți crea cont";
                  }
                },
              }}
              render={({ field }) => {
                return (
                  <div className={styles.GDPRContainer}>
                    <Checkbox
                      checked={field.value}
                      onChange={field.onChange}
                      style={{
                        color: "green",
                      }}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 32,
                        },
                      }}
                    />

                    <p>
                      Sunt de acord cu regulamentul{" "}
                      <span ref={openModalRef} className={styles.GDPRWord}>
                        GDPR
                      </span>
                    </p>
                  </div>
                );
              }}
            />

            <CustomModal
              elementRef={openModalRef}
              title="Regulament GDPR ITFest"
            >
              {GDPRText}
            </CustomModal>

            <p className={styles.inputError}>
              {methods.formState.errors.GDPR &&
                methods.formState.errors.GDPR.message}
            </p>

            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "#399345",
                  color: "white",
                  borderRadius: "1rem",
                  marginTop: "3vh",
                  padding: "10px 40px",
                  "&:hover": {
                    backgroundColor: "#075011",
                  },
                }}
              >
                Register
              </Button>
            </div>
          </form>
        </FormProvider>
      </FormContainer>
    </div>
  );
}

export default SignUpPage;
