export const GDPRText = (
  <div class="c10 c16 doc-content">
    <p class="c23">
      <span class="c8 c1">
        Acord privind prelucrarea datelor cu caracter personal
      </span>
    </p>
    <p class="c7">
      <span>
        Deoarece asigurarea protecției dvs. în ceea ce privește prelucrare
        datelor cu caracter personal, reprezintă pentru noi un obiectiv extrem
        de important, am depus toate diligentele necesare pentru a ne conforma
        standardelor impuse prin Regulamentul UE 2016/679{" "}
      </span>
      <span>(</span>
      <span class="c24">
        <a
          class="c4"
          href="https://www.google.com/url?q=https://eur-lex.europa.eu/legal-content/ro/TXT/PDF/?uri%3DCELEX:32016R0679&amp;sa=D&amp;source=editors&amp;ust=1694984019125302&amp;usg=AOvVaw36TG9aQCHrsHtpr7TwzeZp"
        >
          https://eur-lex.europa.eu/legal-content/ro/TXT/PDF/?uri=CELEX:32016R0679
        </a>
      </span>
      <span class="c3">
        ) și prin orice alt act normativ în vigoare pe teritoriul României.
      </span>
    </p>
    <p class="c7">
      <span class="c3">
        Un pas important pentru realizarea acestui obiectiv este reprezentat de
        informarea în legătură cu modul în care datele dumneavoastră vor fi
        prelucrate (prelucrare înseamnă orice operațiune sau set de operațiuni
        efectuate asupra datelor cu caracter personal sau asupra seturilor de
        date cu caracter personal, cu sau fără utilizarea de mijloace
        automatizate, cum ar fi colectarea, înregistrarea, organizarea,
        structurarea, stocarea, adaptarea sau modificarea, extragerea,
        consultarea, utilizarea, divulgarea prin transmitere, diseminarea sau
        punerea la dispoziție în orice alt mod, alinierea sau combinarea,
        restricționarea, ștergerea sau distrugerea).
      </span>
    </p>
    <p class="c7">
      <span>Ne rezervăm dreptul de a modifica și actualiza </span>
      <span>Politica</span>
      <span>&nbsp;de Confidențialitate și </span>
      <span>Politica</span>
      <span class="c3">&nbsp;de Cookie.</span>
    </p>
    <h3 class="c15" id="h.5m14r7djhjq4">
      <span class="c1 c8">
        1. Cine suntem și modul în care putem fi contactați:
      </span>
    </h3>
    <p class="c0">
      <span class="c3"></span>
    </p>
    <p class="c12">
      <span>
        SiSC este o organizație studențească, neguvernamentală, autonomă,
        independentă și{" "}
      </span>
      <span>nepartinică</span>
      <span class="c3">
        &nbsp;înființată în baza Legii nr. 21/1924 și a Legii 54/1991.
      </span>
    </p>
    <p class="c0">
      <span class="c3"></span>
    </p>
    <p class="c12">
      <span class="c3">
        SiSC se constituie ca principalul partener studențesc și neguvernamental
        în raport cu structurile Facultății de Cibernetică, Statistică și
        Informatică Economică din cadrul Academiei de Studii Economice din
        București.
      </span>
    </p>
    <p class="c0">
      <span class="c3"></span>
    </p>
    <p class="c12">
      <span class="c3">
        SiSC se constituie prin asocierea liberă a membrilor săi ca persoană
        juridică de drept privat fără scop patrimonial, în conformitate cu
        prevederile Legii 62/2011 și cu OG nr. 26/2000, completată prin OG nr.
        37/2003.
      </span>
    </p>
    <p class="c0">
      <span class="c3"></span>
    </p>
    <p class="c12">
      <span>SiSC se evidențiază printr-un reprezentant legal:</span>
      <span class="c1">&nbsp;</span>
      <span class="c1">
        <a
          class="c4"
          href="https://www.google.com/url?q=https://www.facebook.com/razvan.chiscaneanu.9?__cft__%5B0%5D%3DAZVvRhCyo6Ew-PPL5HnNmAguK_whOZ1g8pRJj4vxI931r5C0qk7778z7c6MGy1NhgVlRB6wfxqP1zfmZqtNZVvZrbfiN_FMs5aF1EbmxXtUG0N39PSCtJ70cwEDHfFqbmfjd4jNOq9jrck1GA-1Hadjl_WLF6aPsXfuSvF0WGAm_LYJSZltVNuzRKO-c078HL0E%26__tn__%3D-%5DK*F&amp;sa=D&amp;source=editors&amp;ust=1694984019127046&amp;usg=AOvVaw0hprFY8LrJNSD2QlhheQAm"
        >
          Răzvan-Georgian{" "}
        </a>
      </span>
      <span class="c1">
        <a
          class="c4"
          href="https://www.google.com/url?q=https://www.facebook.com/razvan.chiscaneanu.9?__cft__%5B0%5D%3DAZVvRhCyo6Ew-PPL5HnNmAguK_whOZ1g8pRJj4vxI931r5C0qk7778z7c6MGy1NhgVlRB6wfxqP1zfmZqtNZVvZrbfiN_FMs5aF1EbmxXtUG0N39PSCtJ70cwEDHfFqbmfjd4jNOq9jrck1GA-1Hadjl_WLF6aPsXfuSvF0WGAm_LYJSZltVNuzRKO-c078HL0E%26__tn__%3D-%5DK*F&amp;sa=D&amp;source=editors&amp;ust=1694984019127385&amp;usg=AOvVaw1WRSw0JTC0TMmmPersbE4W"
        >
          Chiscăneanu
        </a>
      </span>
      <span class="c1">.</span>
    </p>
    <p class="c0">
      <span class="c3"></span>
    </p>
    <p class="c12">
      <span class="c5 c1">Adresă: Str. Frumoasă, nr. 31, sala 11</span>
    </p>
    <p class="c0">
      <span class="c5 c1"></span>
    </p>
    <p class="c12">
      <span class="c1">Nr. de telefon: </span>
      <span class="c1 c10">0744124402</span>
    </p>
    <p class="c0">
      <span class="c5 c1"></span>
    </p>
    <p class="c12">
      <span class="c5 c1">E-mail: office@sisc.ro</span>
    </p>
    <p class="c0">
      <span class="c5 c1"></span>
    </p>
    <p class="c12">
      <span class="c1">Pagina Facebook: </span>
      <span class="c24 c1">
        <a
          class="c4"
          href="https://www.google.com/url?q=https://www.facebook.com/sisc.ase/&amp;sa=D&amp;source=editors&amp;ust=1694984019128228&amp;usg=AOvVaw0yJQ9_9Qo9bWFXMXXvdRhu"
        >
          https://www.facebook.com/sisc.ase/
        </a>
      </span>
    </p>
    <p class="c0">
      <span class="c5 c1"></span>
    </p>
    <p class="c0">
      <span class="c5 c1"></span>
    </p>
    <p class="c0">
      <span class="c5 c1"></span>
    </p>
    <h3 class="c15" id="h.vlj9641sq94o">
      <span class="c8 c1">2. Colectare date/ tipuri de date</span>
    </h3>
    <ol class="c9 lst-kix_heas8g8tjx4t-0 start" start="1">
      <li class="c13 c17 li-bullet-0">
        <span class="c3">Colectate automat în urma accesării (de tipul)</span>
      </li>
    </ol>
    <ul class="c9 lst-kix_heas8g8tjx4t-1 start">
      <li class="c11 li-bullet-0">
        <span class="c3">Adresă IP;</span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3">Data și ora accesului;</span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3">Conținutul cererii (website specific);</span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3">Starea accesului/codul de stare HTTP;</span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3">Volumul de date transferat;</span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3">Acces solicitat la website;</span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3">
          Browser, setări de limbă, versiunea de browser, sistem de operare și
          suprafață.
        </span>
      </li>
    </ul>
    <ol class="c9 lst-kix_heas8g8tjx4t-0" start="2">
      <li class="c13 c17 li-bullet-0">
        <span>Colectate în urma completării formularului de contact:</span>
      </li>
    </ol>
    <ul class="c9 lst-kix_heas8g8tjx4t-1 start">
      <li class="c11 li-bullet-0">
        <span class="c3 c10">Nume și Prenume;</span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3 c10">Adresă de email;</span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3 c10">Număr de telefon;</span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3 c10">Unitatea de învățământ;</span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3 c10">Anul de studiu;</span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3 c10">CV.</span>
      </li>
    </ul>
    <h3 class="c15" id="h.7ze9sv8lg8nx">
      <span class="c8 c1">3. Fișierele cookie</span>
    </h3>
    <h4 class="c22" id="h.dmnb34mtc3b4">
      <span class="c1 c28">
        Acest Website nu folosește așa-numitele „fișiere cookie”.
      </span>
    </h4>
    <h3 class="c15" id="h.17eb8r4fxpn">
      <span class="c1 c14">
        4. Scopul prelucrării datelor în vederea realizării proiectului{" "}
      </span>
      <span class="c14 c1">ITFest</span>
      <span class="c8 c1">&nbsp;ediția 2023. </span>
    </h3>
    <ol class="c9 lst-kix_rqedcu6s80mh-0 start" start="1">
      <li class="c13 c17 li-bullet-0">
        <span class="c3">
          În vederea realizării unei comunicări cu dumneavoastră
        </span>
      </li>
    </ol>
    <ol class="c9 lst-kix_g3u1aw7jqtn4-0 start" start="1">
      <li class="c11 li-bullet-0">
        <span class="c3">
          În cazul în care inițiați o comunicare cu noi, datele dumneavoastră
          vor fi stocate pentru perioada necesară realizării scopului în care
          ne-ați contactat sau până la momentul în care ne solicitați ștergerea
          datelor, oricare dintre aceste momente ar surveni primul;
        </span>
      </li>
    </ol>
    <ol class="c9 lst-kix_rqedcu6s80mh-0" start="2">
      <li class="c13 c17 li-bullet-0">
        <span class="c3">
          Pentru a vă putea oferi servicii special configurate nevoilor
          dumneavoastră
        </span>
      </li>
      <li class="c13 c17 li-bullet-0">
        <span class="c3">În scopuri de marketing</span>
      </li>
    </ol>
    <ol class="c9 lst-kix_931cpkku318l-0 start" start="1">
      <li class="c11 li-bullet-0">
        <span class="c3">
          Putem utiliza informaţiile respective pentru îmbunătăţirea produselor
          şi serviciilor pe care vi le oferim;
        </span>
      </li>
    </ol>
    <ol class="c9 lst-kix_rqedcu6s80mh-0" start="4">
      <li class="c13 c17 li-bullet-0">
        <span class="c3">Pentru apărarea intereselor noastre legitime</span>
      </li>
    </ol>
    <ol class="c9 lst-kix_44vh3l5cpi45-0 start" start="1">
      <li class="c11 li-bullet-0">
        <span class="c3">
          Pentru apărarea intereselor noastre legitime (În cazul unor situații
          excepționale precum atacuri cibernetice care pot periclita siguranța
          platformei noastre sau în cazul în care informațiile sunt solicitate
          de către instituțiile publice competente în vederea soluționării
          anchetelor în desfășurare, ne rezervăm dreptul de prelucra
          informațiile vizate).
        </span>
      </li>
    </ol>
    <h3 class="c15 c21" id="h.tp00184zwjpl">
      <span class="c8 c1"></span>
    </h3>
    <h3 class="c15" id="h.j0uxmb7jfzyd">
      <span class="c8 c1">
        5. Cât timp păstrăm datele dumneavoastră cu caracter personal
      </span>
    </h3>
    <p class="c6">
      <span class="c3">
        Durata de timp pentru care vor fi păstrate datele dumneavoastră este
        limitată și va fi determinate de perioada necesară îndeplinirii
        scopurilor în vederea cărora sunt prelucrate datele, această perioadă
        neputând să depășească 2 ani.
      </span>
    </p>
    <h3 class="c15" id="h.b6p8o4wt4l8j">
      <span class="c14 c1">6. Terți</span>
    </h3>
    <p class="c13">
      <span class="c10">
        Adobe, Systematic, N-Able, Emia, Sievo, 4mayo &nbsp;
      </span>
      <sup>
        <a href="#cmnt1" id="cmnt_ref1">
          [a]
        </a>
      </sup>
    </p>
    <ol class="c9 lst-kix_u6wysbnrtp9-0 start" start="1">
      <li class="c13 c17 li-bullet-0">
        <span class="c3">
          Scop: Trimiterea de materiale promoționale, accesul dumneavoastră la
          oferte de muncă și informații de interes. &nbsp;
        </span>
      </li>
    </ol>
    <p class="c2">
      <span class="c3">Mențiune</span>
    </p>
    <ol class="c9 lst-kix_z94ypkfdm99v-2 start" start="1">
      <li class="c13 c20 li-bullet-0">
        <span class="c3">
          În cazul în care ne revine obligația legală sau dacă este necesar
          pentru a ne apăra un interes legitim, putem de asemenea divulga
          anumite date cu caracter personal unor autorități publice.
        </span>
      </li>
      <li class="c13 c20 li-bullet-0">
        <span class="c3">
          Ne asigurăm că accesul la datele dvs. de către terții persoane
          juridice de drept privat se realizeaza în conformitate cu prevederile
          legale privind protecția datelor și confidențialitatea informațiilor,
          în baza unor contracte încheiate cu aceștia.
        </span>
      </li>
    </ol>
    <h3 class="c15" id="h.i95wkvjjp613">
      <span class="c8 c1">7. Teritorialitate:</span>
    </h3>
    <p class="c6">
      <span class="c3">
        Prelucrarea datelor se face doar pe teritoriul României.{" "}
      </span>
    </p>
    <h3 class="c15" id="h.c3qpculflx51">
      <span class="c8 c1">8. Drepturile Dvs</span>
    </h3>
    <h4 class="c22" id="h.v3z4iznt5yfp">
      <span class="c5 c1">Dreptul de acces al persoanei vizate</span>
    </h4>
    <p class="c6">
      <span class="c3">
        (1) &nbsp; Persoana vizată are dreptul de a obține din partea
        operatorului o confirmare că se prelucrează sau nu date cu caracter
        personal care o privesc și, în caz afirmativ, acces la datele respective
        și la următoarele informații:
      </span>
    </p>
    <ol class="c9 lst-kix_fuiagpwge42a-1 start" start="1">
      <li class="c11 li-bullet-0">
        <span class="c3">scopurile prelucrării;</span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3">categoriile de date cu caracter personal vizate;</span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3">
          destinatarii sau categoriile de destinatari cărora datele cu caracter
          personal le-au fost sau urmează să le fie divulgate, în special
          destinatari din țări terțe sau organizații internaționale;
        </span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3">
          acolo unde este posibil, perioada pentru care se preconizează că vor
          fi stocate datele cu caracter personal sau, dacă acest lucru nu este
          posibil, criteriile utilizate pentru a stabili această perioadă;{" "}
        </span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3">
          existența dreptului de a solicita operatorului rectificarea sau
          ștergerea datelor cu caracter personal ori restricționarea prelucrării
          datelor cu caracter personal referitoare la persoana vizată sau a
          dreptului de a se opune prelucrării;
        </span>
      </li>
      <li class="c11 li-bullet-0">
        <span class="c3">
          dreptul de a depune o plângere în fața unei autorități de
          supraveghere;
        </span>
      </li>
    </ol>
    <h4 class="c22" id="h.a1u4rzbtzelt">
      <span class="c5 c1">Dreptul la rectificare</span>
    </h4>
    <p class="c6">
      <span class="c3">
        (1) &nbsp; Persoana vizată are dreptul de a obține de la operator, fără
        întârzieri nejustificate, rectificarea datelor cu caracter personal
        inexacte care o privesc. Ținându-se seama de scopurile în care au fost
        prelucrate datele, persoana vizată are dreptul de a obține completarea
        datelor cu caracter personal care sunt incomplete, inclusiv prin
        furnizarea unei declarații suplimentare.
      </span>
    </p>
    <h4 class="c22" id="h.nl5lipqskyuw">
      <span class="c5 c1">
        Dreptul la ștergerea datelor („dreptul de a fi uitat”)
      </span>
    </h4>
    <p class="c6">
      <span class="c3">
        (1) &nbsp; Persoana vizată are dreptul de a obține din partea
        operatorului ștergerea datelor cu caracter personal care o privesc, fără
        întârzieri nejustificate, iar operatorul are obligația de a șterge
        datele cu caracter personal fără întârzieri nejustificate în cazul în
        care se aplică unul dintre următoarele motive:
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (a) datele cu caracter personal nu mai sunt necesare pentru îndeplinirea
        scopurilor pentru care au fost colectate sau prelucrate;
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (b) persoana vizată își retrage consimțământul pe baza căruia are loc
        prelucrarea, în conformitate cu articolul 6 alineatul (1) litera (a) sau
        cu articolul 9 alineatul (2) litera (a), și nu există niciun alt temei
        juridic pentru prelucrarea;
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (c) persoana vizată se opune prelucrării în temeiul articolului 21
        alineatul (1) și nu există motive legitime care să prevaleze în ceea ce
        privește prelucrarea sau persoana vizată se opune prelucrării în temeiul
        articolului 21 alineatul (2);
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (d) datele cu caracter personal au fost prelucrate ilegal;
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (e) datele cu caracter personal trebuie șterse pentru respectarea unei
        obligații legale care revine operatorului în temeiul dreptului Uniunii
        sau al dreptului intern sub incidența căruia se află operatorul;
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (f) datele cu caracter personal au fost colectate în legătură cu
        oferirea de servicii ale societății informaționale menționate la
        articolul 8 alineatul (1).
      </span>
    </p>
    <p class="c6">
      <span class="c3">
        (2) &nbsp; În cazul în care operatorul a făcut publice datele cu
        caracter personal și este obligat, în temeiul alineatului (1), să le
        șteargă, operatorul, ținând seama de tehnologia disponibilă și de costul
        implementării, ia măsuri rezonabile, inclusiv măsuri tehnice, pentru a
        informa operatorii care prelucrează datele cu caracter personal că
        persoana vizată a solicitat ștergerea de către acești operatori a
        oricăror linkuri către datele respective sau a oricăror copii sau
        reproduceri ale acestor date cu caracter personal.
      </span>
    </p>
    <p class="c6">
      <span class="c3">
        (3) &nbsp; Alineatele (1) și (2) nu se aplică în măsura în care
        prelucrarea este necesară:
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (a) pentru exercitarea dreptului la liberă exprimare și la informare;
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (b) pentru respectarea unei obligații legale care prevede prelucrarea în
        temeiul dreptului Uniunii sau al dreptului intern care se aplică
        operatorului sau pentru îndeplinirea unei sarcini executate în interes
        public sau în cadrul exercitării unei autorități oficiale cu care este
        învestit operatorul;
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (c) din motive de interes public în domeniul sănătății publice, în
        conformitate cu articolul 9 alineatul (2) literele (h) și (i) și cu
        articolul 9 alineatul (3);
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (d) în scopuri de arhivare în interes public, în scopuri de cercetare
        științifică sau istorică ori în scopuri statistice, în conformitate cu
        articolul 89 alineatul (1), în măsura în care dreptul menționat la
        alineatul (1) este susceptibil să facă imposibilă sau să afecteze în mod
        grav realizarea obiectivelor prelucrării respective;{" "}
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (e) pentru constatarea, exercitarea sau apărarea unui drept în instanță.
      </span>
    </p>
    <h4 class="c22" id="h.fm293xyiswlf">
      <span class="c5 c1">Dreptul la restricționarea prelucrării</span>
    </h4>
    <p class="c6">
      <span class="c3">
        (1) &nbsp; Persoana vizată are dreptul de a obține din partea
        operatorului restricționarea prelucrării în cazul în care se aplică unul
        din următoarele cazuri:
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (a) persoana vizată contestă exactitatea datelor, pentru o perioadă care
        îi permite operatorului să verifice exactitatea datelor;
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (b) prelucrarea este ilegală, iar persoana vizată se opune ștergerii
        datelor cu caracter personal, solicitând în schimb restricționarea
        utilizării lor;
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (c) operatorul nu mai are nevoie de datele cu caracter personal în
        scopul prelucrării, dar persoana vizată i le solicită pentru
        constatarea, exercitarea sau apărarea unui drept în instanță;{" "}
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (d) persoana vizată s-a opus prelucrării în conformitate cu articolul 21
        alineatul (1), pentru intervalul de timp în care se verifică dacă
        drepturile legitime ale operatorului prevalează asupra celor ale
        persoanei vizate.
      </span>
    </p>
    <p class="c6">
      <span class="c3">
        (2) &nbsp; În cazul în care prelucrarea a fost restricționată în temeiul
        alineatului (1), astfel de date cu caracter personal pot, cu excepția
        stocării, să fie prelucrate numai cu consimțământul persoanei vizate sau
        pentru constatarea, exercitarea sau apărarea unui drept în instanță sau
        pentru protecția drepturilor unei alte persoane fizice sau juridice sau
        din motive de interes public important al Uniunii sau al unui stat
        membru.
      </span>
    </p>
    <p class="c6">
      <span class="c3">
        (3) &nbsp; O persoană vizată care a obținut restricționarea prelucrării
        în temeiul alineatului (1) este informată de către operator înainte de
        ridicarea restricției de prelucrare.
      </span>
    </p>
    <h4 class="c22" id="h.5alx60fe9h4s">
      <span class="c1 c5">Dreptul la portabilitatea datelor</span>
    </h4>
    <p class="c6">
      <span class="c3">
        (1) &nbsp; Persoana vizată are dreptul de a primi datele cu caracter
        personal care o privesc și pe care le-a furnizat operatorului într-un
        format structurat, utilizat în mod curent și care poate fi citit automat
        și are dreptul de a transmite aceste date altui operator, fără obstacole
        din partea operatorului căruia i-au fost furnizate datele cu caracter
        personal, în cazul în care:
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (a) prelucrarea se bazează pe consimțământ în temeiul articolului 6
        alineatul (1) litera (a) sau al articolului 9 alineatul (2) litera (a)
        sau pe un contract în temeiul articolului 6 alineatul (1) litera (b);{" "}
      </span>
    </p>
    <p class="c2">
      <span class="c3">
        (b) prelucrarea este efectuată prin mijloace automate.
      </span>
    </p>
    <p class="c6">
      <span class="c3">
        (2) &nbsp; În exercitarea dreptului său la portabilitatea datelor în
        temeiul alineatului (1), persoana vizată are dreptul ca datele cu
        caracter personal să fie transmise direct de la un operator la altul
        acolo unde acest lucru este fezabil din punct de vedere tehnic.
      </span>
    </p>
    <p class="c6">
      <span class="c3">
        (3) &nbsp; Exercitarea dreptului menționat la alineatul (1) din
        prezentul articol nu aduce atingere articolului 17. Respectivul drept nu
        se aplică prelucrării necesare pentru îndeplinirea unei sarcini
        executate în interes public sau în cadrul exercitării unei autorități
        oficiale cu care este învestit operatorul.
      </span>
    </p>
    <p class="c6">
      <span class="c3">
        (4) &nbsp; Dreptul menționat la alineatul (1) nu aduce atingere
        drepturilor și libertăților altora.
      </span>
    </p>
    <h4 class="c22" id="h.cm7yqcqdmuh1">
      <span class="c5 c1">
        Dreptul de a fi notificat în legătură cu rectificarea, ștergerea sau
        restricționarea prelucrării datelor cu caracter personal
      </span>
    </h4>
    <p class="c6">
      <span class="c3">
        (1) &nbsp; Operatorul comunică fiecărui destinatar căruia i-au fost
        divulgate datele cu caracter personal orice rectificare sau ștergere a
        datelor cu caracter personal sau restricționare a prelucrării efectuate
        în conformitate cu articolul 16, articolul 17 alineatul (1) și articolul
        18, cu excepția cazului în care acest lucru se dovedește imposibil sau
        presupune eforturi disproporționate. Operatorul informează persoana
        vizată cu privire la destinatarii respectivi dacă persoana vizată
        solicită acest lucru.
      </span>
    </p>
    <p class="c13">
      <span class="c5 c1">9. Date colectate în cadrul evenimentului</span>
    </p>
    <p class="c13">
      <span class="c3">
        Prin această notă dorim să vă informăm cu privire la:
      </span>
    </p>
    <p class="c13">
      <span class="c3">
        (i) scopurile pentru care colectăm și prelucrăm datele dvs. cu caracter
        personal;
      </span>
    </p>
    <p class="c13">
      <span class="c3">(ii) temeiul legal al prelucrării acestor date;</span>
    </p>
    <p class="c13">
      <span class="c3">
        (iii) categoriile de date pe care le colectăm de la dvs. și pe care le
        prelucrăm;
      </span>
    </p>
    <p class="c13">
      <span class="c3">(iv) durata prelucrării acestor date;</span>
    </p>
    <p class="c13">
      <span class="c3">
        (v) drepturile de care beneficiați în calitate de persoană vizată și
        modalitatea în care vi le poți exercita;
      </span>
    </p>
    <p class="c13">
      <span class="c3">
        (vi) cui dezvăluim datele dvs. cu caracter personal.
      </span>
    </p>
    <p class="c13">
      <span class="c5 c1">
        Scopul pentru care sunt colectate și prelucrate datele
      </span>
    </p>
    <p class="c13">
      <span>
        Datele personale care ne sunt transmise prin intermediul CV-urilor
        și/sau care sunt disponibile pe platformele de specialitate (de exemplu,
        LinkedIn) sunt prelucrate în vederea participării &nbsp;la evenimentele
      </span>
      <span class="c3 c10">&nbsp;din cadrul ITFest 2023.</span>
    </p>
    <p class="c13">
      <span class="c5 c1">Categoriile de date colectate și prelucrate</span>
    </p>
    <p class="c13">
      <span class="c3">
        Datele cu caracter personal pe care le prelucrăm sunt cele pe care ni le
        puneți la dispoziție în cadrul CV-ului și/sau care sunt disponibile pe
        platformele de specialitate și includ, printre altele: numele și
        prenumele, fotografia, adresa de domiciliu și/sau adresa de reședință,
        adresa de e-mail personală, numărul de telefon personal, data nașterii,
        sexul, starea civilă, naționalitatea, experiența profesională, studii,
        abilități și cunoștințe, precum și orice alte date cu caracter personal
        incluse în CV-ul pe care îl transmiteți.
      </span>
    </p>
    <p class="c13">
      <span class="c3">
        În măsura în care documentele pe care ni le transmiteți conțin și datele
        personale ale altor persoane fizice (de exemplu, persoane de contact
        pentru verificarea referințelor), vă rugăm să informați respectivele
        persoane anterior transmiterii către SiSC a datelor lor personale cu
        privire la modalitatea în care datelor lor cu caracter personal vor fi
        prelucrate de către societate.
      </span>
    </p>
    <p class="c13">
      <span class="c5 c1">Durata prelucrării acestor date</span>
    </p>
    <p class="c13">
      <span>
        Datele cu caracter personal pe care ni le puneți la dispoziție în cadrul
        CV-ului și/sau care sunt disponibile pe platformele de specialitate vor
        fi stocate și prelucrate pe durata desfășurării proiectulu
      </span>
      <span class="c10">i ITFest 2023,</span>
      <span class="c3">
        &nbsp;urmând ca la finalul proiectului acestea să fie șterse.
      </span>
    </p>
    <p class="c13">
      <span class="c5 c1">Securitatea prelucrării</span>
    </p>
    <p class="c13">
      <span class="c3">
        SiSC va asigura securitatea prelucrării datelor dvs. începând cu
        momentul conceperii acestora prin implementarea unor măsuri tehnice și
        organizatorice adecvate.
      </span>
    </p>
    <p class="c13">
      <span class="c3">
        De asemenea, SiSC vă va informa, fără întârzieri nejustificate, în cazul
        în care o eventuală încălcare a securității datelor este susceptibilă să
        genereze un risc ridicat pentru drepturile și libertățile dvs., cu
        excepția cazurilor indicate în art. 34 din Regulament.
      </span>
    </p>
    <p class="c13">
      <span class="c5 c1">
        Drepturile de care beneficiați în calitate de persoană vizată
      </span>
    </p>
    <p class="c13">
      <span class="c3">
        În considerarea calității dvs. de persoană vizată, beneficiați de
        următoarele drepturi:
      </span>
    </p>
    <ul class="c9 lst-kix_guaeex1syxkq-0 start">
      <li class="c13 c17 li-bullet-0">
        <span class="c3">
          Dreptul de a fi informat cu privire la toate aspectele esențiale ale
          prelucrării de date;
        </span>
      </li>
    </ul>
    <ul class="c9 lst-kix_yj8w7ralvg40-0 start">
      <li class="c13 c17 li-bullet-0">
        <span class="c3">
          Dreptul de acces la datele prelucrate și la o serie de informații,
          conform prevederilor legale, drept care vă permite să fiți informat
          dacă datele dvs. personale sunt prelucrate de noi , precum și cu
          privire la detaliile disponibile în acest sens (inclusiv dar fără a se
          limita la scopurile prelucrării, categoriile de date cu caracter
          personal vizate, destinatarii sau categoriile de destinatari cărora
          datele cu caracter personal le-au fost sau urmează să le fie divulgate
          etc.), &nbsp;
        </span>
      </li>
    </ul>
    <ul class="c9 lst-kix_esupmtrse1ac-0 start">
      <li class="c13 c17 li-bullet-0">
        <span class="c3">
          Dreptul de a obține rectificarea și/sau completarea datelor personale,
          fără întârzieri nejustificate;
        </span>
      </li>
    </ul>
    <ul class="c9 lst-kix_xjzo3sdlva9c-0 start">
      <li class="c13 c17 li-bullet-0">
        <span class="c3">
          Dreptul de a obține ștergerea datelor personale, fără întârzieri
          nejustificate, în cazurile prevăzute de lege (de exemplu, atunci când
          datele cu caracter personal nu mai sunt necesare pentru îndeplinirea
          scopurilor pentru care au fost colectate sau prelucrate);
        </span>
      </li>
    </ul>
    <ul class="c9 lst-kix_5l87xczdiziw-0 start">
      <li class="c13 c17 li-bullet-0">
        <span>
          Dreptul de a obține restricționarea prelucrării în cazurile prevăzute
          de lege (de exemplu, atunci când{" "}
        </span>
        <span>contestați</span>
        <span class="c3">
          &nbsp;exactitatea datelor, pentru o perioadă care permite operatorului
          să verifice exactitatea datelor).
        </span>
      </li>
    </ul>
    <ul class="c9 lst-kix_flvn7hu6bt17-0 start">
      <li class="c13 c17 li-bullet-0">
        <span class="c3">
          Dreptul la portabilitatea datelor Aveți dreptul de a obține date
          personale prelucrate într-un mod structurat, utilizat în mod curent și
          care poate fi citit automat în scopul de a transmite aceste date altui
          operator, fără obstacole din partea operatorului, în cazurile
          prevăzute de art. 20 din Regulament.
        </span>
      </li>
    </ul>
    <ul class="c9 lst-kix_o6hfh8efn0mr-0 start">
      <li class="c13 c17 li-bullet-0">
        <span class="c3">
          Dreptul la opoziție Aveți dreptul de a vă opune, din motive legate de
          situația dvs. particulară, prelucrării în temeiul interesului public
          sau al interesului legitim al operatorului sau în scopuri de marketing
          direct, inclusiv creării de profiluri pe baza respectivelor
          justificări, cu excepția prevăzute de art. 21 din Regulament.
        </span>
      </li>
    </ul>
    <ul class="c9 lst-kix_cznzls4khtyw-0 start">
      <li class="c13 c17 li-bullet-0">
        <span class="c3">
          Dreptul de a nu face obiectul unei decizii bazate exclusiv pe
          prelucrarea automată, în afara excepțiilor prevăzute de art. 22 din
          Regulament.
        </span>
      </li>
    </ul>
    <ul class="c9 lst-kix_tajdit7tu49y-0 start">
      <li class="c13 c17 li-bullet-0">
        <span class="c3">
          Dreptul de a depune o plângere în fața Autorităţii Naţionale de
          Supraveghere a Prelucrării Datelor cu Caracter Personal (ANSPDCP).
        </span>
      </li>
      <li class="c13 c17 li-bullet-0">
        <span class="c3">
          Dreptul la formula o &nbsp;cale de atac, în fața instanțelor de
          judecată din România.
        </span>
      </li>
    </ul>
    <p class="c13">
      <span class="c5 c1">10. Dispoziții finale</span>
    </p>
    <p class="c13">
      <span>
        Fără a vă afecta dreptul dumneavoastră de a contacta în orice moment
        autoritatea de supraveghere, puteți să ne contactați în prealabil la
        adresa de email{" "}
      </span>
      <span class="c24 c1">
        <a class="c4" href="mailto:office@sisc.ro">
          office@sisc.ro
        </a>
      </span>
      <span class="c1">&nbsp;</span>
      <span class="c3">
        și vom depune toate eforturile necesare pentru a rezolva orice problemă.
      </span>
    </p>
    <p class="c13">
      <span class="c3">
        Aveți dreptul să depuneți o plângere la autoritatea de supraveghere cu
        privire la prelucrarea datelor dvs. cu caracter personal. În România,
        datele de contact ale autorității de supraveghere pentru protecția
        datelor sunt următoarele:
      </span>
    </p>
    <p class="c13">
      <span class="c3">
        Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter
        Personal
      </span>
    </p>
    <p class="c13">
      <span class="c3">
        B-dul G-ral. Gheorghe Magheru nr. 28-30, Sector 1, cod poștal 010336,
        București, Romania
      </span>
    </p>
    <p class="c13">
      <span class="c3">Telefon: +40.318.059.211 sau +40.318.059.212;</span>
    </p>
    <p class="c13">
      <span class="c3">E-mail:anspdcp@dataprotection.ro</span>
    </p>
    <p class="c6 c18">
      <span class="c3"></span>
    </p>
    <p class="c0">
      <span class="c8 c1"></span>
    </p>
    <div class="c26">
      <p class="c25">
        <a href="#cmnt_ref1" id="cmnt1">
          [a]
        </a>
        <span class="c3">
          aici trebuie sa ved exact cu cine facem parteneriatul
        </span>
      </p>
    </div>
  </div>
);
