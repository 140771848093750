import React, { useRef, useState } from 'react'
import styles from './WorkshopPage.module.css'
import Description from '../../../components/description/Description'
import { workshopDescription } from '../../../staticData/general/workshopDescription'
import { workshopCompanyData } from "../../../staticData/workshop/workshopData"
import CompanyCard from '../../../components/card/CompanyCard'
import { Alert, Button, Slide, Snackbar } from '@mui/material'
import { workshopEnd } from "../../../staticData/dates/workshopDates"
import useStore from '../../../store/userStore'
import Axios from 'axios'
import { URL } from '../../../constants'

const workshopID = 4

function WorkshopPage() {
  const [user, eventsAttended, addEventAttended] = useStore((state) => [state.user, state.eventsAttended, state.addEventAttended])

  const [openToast, setOpenToast] = useState(false)
  const toastObj = useRef({
    type: "error",
    message: ""
  })

  function workshopSignUp(){
    Axios.post(`${URL}/attendance/${user.id}`, {
      eventId: workshopID
    }).then(() => {
      addEventAttended(workshopID)
    }).catch((error) => {
      toastObj.current.message = "Eroare de server"
      setOpenToast(true)

      console.log(error)
    })
  }

  return (
    <div>
      <Description text={workshopDescription} />

      <h2 className={styles.workshopDate}>25 octombrie</h2>

        <div className={styles.workshopContainer}>
          <CompanyCard eventData={workshopCompanyData} />
          {
          new Date() < workshopEnd && (eventsAttended[workshopID] ? 
          <p className={styles.signedUp}>Ești interesat</p>
          :
          <Button
          onClick={workshopSignUp}
          variant="contained"
          sx={{
              backgroundColor: "#1A9A32",
              borderRadius: "1.5rem",
              '&:hover': {
                backgroundColor: "#136B1E",
              },
          }}>
          Sunt interesat
          </Button> 
        )}
        </div>

        <Snackbar 
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={openToast}
      autoHideDuration={3000}
      onClose={() => setOpenToast(false)}
      TransitionComponent={Slide}
      >
        <Alert severity={toastObj.current.type}>{toastObj.current.message}</Alert>
      </Snackbar>
    </div>
  )
}

export default WorkshopPage