import React, { useRef, useState } from 'react'

import styles from './JobHubPage.module.css'
import Description from '../../../components/description/Description'
import { jobHubDescription } from "../../../staticData/general/jobhubDescription"
import { Link } from 'react-router-dom'

import { jobHubCompanies } from '../../../staticData/jobHub/jobHubData'
import CompanyCard from '../../../components/card/CompanyCard'
import { jobHubEnd } from '../../../staticData/dates/jobHubDates'
import { Alert, Button, Slide, Snackbar } from '@mui/material'
import useStore from '../../../store/userStore'
import Axios from 'axios'
import { URL } from '../../../constants'

const jobHubID = 2

function JobHubPage() {
  const [user, eventsAttended, addEventAttended] = useStore((state) => [state.user, state.eventsAttended, state.addEventAttended])

  const [openToast, setOpenToast] = useState(false)
  const toastObj = useRef({
    type: "error",
    message: ""
  })

  function jobHubSignUp(){
    Axios.post(`${URL}/attendance/${user.id}`, {
      eventId: jobHubID
    }).then(() => {
      addEventAttended(jobHubID)
    }).catch((error) => {
      toastObj.current.message = "Eroare de server"
      setOpenToast(true)

      console.log(error)
    })
  }

  return (
    <div>
      <Description text={jobHubDescription} />

      <p className={styles.location}>Locație: <Link to="https://maps.app.goo.gl/EikEHo9P7NVoyhm29" target="_blank" style={{
        textDecoration: "underline"
      }}>Clădirea Virgil Madgearu</Link>
      </p>

      <div className={styles.cardsContainer}>
          {jobHubCompanies.map((jobHubCompanyData, index) => {
            return (
                <CompanyCard key={index} eventData={jobHubCompanyData} />
        )
          })}
      </div>

      <div className={styles.buttonContainer}>
      {
        new Date() < jobHubEnd && (eventsAttended[jobHubID] ? 
        <p className={styles.signedUp}>Ești interesat</p>
        :
        <Button
        onClick={jobHubSignUp}
        variant="contained"
        sx={{
            backgroundColor: "#1A9A32",
            borderRadius: "1.5rem",
            '&:hover': {
              backgroundColor: "#136B1E",
            },
        }}>
         Sunt interesat
        </Button> 
      )}
      </div>

      <Snackbar 
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      open={openToast}
      autoHideDuration={3000}
      onClose={() => setOpenToast(false)}
      TransitionComponent={Slide}
      >
        <Alert severity={toastObj.current.type}>{toastObj.current.message}</Alert>
      </Snackbar>
    </div>
  )
}

export default JobHubPage