import React, { useEffect, useRef, useState } from "react"
import styles from "./HackathonPage.module.css"
import CompanyCard from "../../../components/card/CompanyCard"
import HackathonSignUp from "../../../components/card/actions/HackathonSignUp"
import useStore from "../../../store/userStore"
import CustomModal from "../../../components/modal/CustomModal"
import Description from "../../../components/description/Description"

import { hackathonDescription } from "../../../staticData/general/hackathonDescription"

import { hackathonAdobe } from "../../../staticData/hackathons/adobeHackathon"
import { hackathonNable } from "../../../staticData/hackathons/n-ableHackathon"
import EventStatus from "../../../components/eventStatus/EventStatus"
import {
    signUpHackathon1End,
    signUpHackathon1Start,
} from "../../../staticData/dates/hackathonDates"

const hackathons = [hackathonAdobe, hackathonNable]

function HackathonPage() {
    const [user, eventsAttended] = useStore((state) => [
        state.user,
        state.eventsAttended,
    ])

    const [signedUpHackathonID, setSignedUpHackathonID] = useState(null)

    const openModalRef = useRef()

    useEffect(() => {
        for (const hackathon of hackathons) {
            const hackathonID = hackathon.id

            if (eventsAttended[hackathonID]) {
                setSignedUpHackathonID(hackathonID)
                break
            }
        }
    }, [eventsAttended])

    return (
        <div>
            <Description text={hackathonDescription} />

            <h1 className={styles.hackathonID}>
                Your hackathon ID:
                <span className={styles.userID}>{user.id}</span>
                <img
                    ref={openModalRef}
                    src="/icons/info-icon.svg"
                    alt="info"
                    className={styles.infoIcon}
                />
            </h1>

            {new Date() >= signUpHackathon1Start &&
                new Date() <= signUpHackathon1End && (
                    <h2 className={styles.hackathonsDate}>
                        <span className={styles.dateText}>28 octombrie</span>
                    </h2>
                )}

            <CustomModal elementRef={openModalRef} title="Înscriere Hackatoane">
                <div>
                    <p className={styles.modalParagraph}>
                        Dacă ești căpitan de echipă, află ID-urile de înscriere
                        ale membrilor
                    </p>
                    <p className={styles.modalParagraph}>
                        Dacă ești membru de echipă, trimite căpitanului ID-ul
                        pentru ca acesta să înscrie echipa la hackathon
                    </p>

                    <p className={styles.modalParagraph}>
                        Atenție: Te poți înscrie la doar unul dintre cele două hackathoane
                    </p>
                </div>
            </CustomModal>

            <div className={styles.cardsContainer}>
                <EventStatus
                    eventTitle="Hackathoanele"
                    startDate={signUpHackathon1Start}
                    endDate={signUpHackathon1End}
                    messageStart="vor începe în curând"
                    messageEnd="s-au încheiat"
                >
                    {hackathons.map((hackathon) => {
                        return (
                            <CompanyCard
                                key={hackathon.id}
                                eventData={hackathon}
                            >
                                <HackathonSignUp
                                    eventID={hackathon.id}
                                    title={hackathon.details.title}
                                    signedUpHackathonID={signedUpHackathonID}
                                    setSignedUpHackathonID={
                                        setSignedUpHackathonID
                                    }
                                />
                            </CompanyCard>
                        )
                    })}
                </EventStatus>
            </div>
        </div>
    )
}

export default HackathonPage
